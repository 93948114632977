const getApiUrl = () => {
  if (window.location.hostname === 'emnr-staging.smint.no') {
    return 'https://emnr-api-staging.smint.no';
  }

  // Production host is default for all production environment,
  // except specific domain names
  if (process.env.NODE_ENV === 'production') {
    return 'https://api.emnr.no';
  }

  return 'http://localhost:8000';
};

const API_URL = getApiUrl();

export default API_URL;
