import React, { useState } from 'react';
import styled from 'styled-components';
import DeleteUserIcon from '@/assets/icons/deleteUser.svg';
import Modal from 'react-modal';
import { modalStyles } from '@/styles/Modals';
import { FlexContainer, HrLine } from '@/styles/Containers';
import { CustomizableHeading } from '@/styles/Text';
import { ModalXButton, RedButton } from '@/styles/Buttons';
import axios from 'axios';
import API_URL from '@/config';
import { PulseLoader } from 'react-spinners';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const TrashBtn = styled.img`
  width: 28px;
  margin-left: 15px;
  cursor: pointer;
  :hover {
    filter: invert(48%) sepia(96%) saturate(3381%) hue-rotate(328deg) brightness(86%) contrast(112%);
  }
`;

interface ReviewProps {
  reviewId: number;
  userName: string;
}

const useBanUserMutation = (handleSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (reviewId: number) => {
      await axios.delete(`${API_URL}/user/ban/?reviewId=${reviewId}`);
    },
    {
      onSuccess: () => {
        handleSuccess();
        queryClient.invalidateQueries(['review']);
      },
    },
  );
};

const BanUser: React.FC<ReviewProps> = ({ reviewId, userName }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const toggleModalIsOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const { mutate, isLoading } = useBanUserMutation(toggleModalIsOpen);

  Modal.setAppElement('#root');

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={toggleModalIsOpen} style={modalStyles} contentLabel='Delete User Modal'>
        <FlexContainer justifyContent='space-between'>
          <CustomizableHeading headingLevel='h1' option='bold'>
            Slett brukeren
          </CustomizableHeading>
          <ModalXButton onClick={toggleModalIsOpen}>&#10006;</ModalXButton>
        </FlexContainer>
        <HrLine margin='5px 0 15px 0' />
        <p>
          Alle anmeldelsene til bruker <b>{userName}</b> vil bli slettet, og vil ikke få mulighet til å legge til flere anmeldelser.
        </p>
        <p>Er du sikker? Denne handlingen kan ikke bli reversert.</p>

        {isLoading ? (
          <RedButton disabled>
            <PulseLoader color='#FFF' size='10px' />
          </RedButton>
        ) : (
          <RedButton onClick={() => mutate(reviewId)}>Ja, jeg er sikker</RedButton>
        )}
      </Modal>
      <TrashBtn src={DeleteUserIcon} onClick={toggleModalIsOpen} />
    </>
  );
};

export default BanUser;
