import React, { useCallback, useEffect, useState, ReactNode } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { FlexContainer, SemesterRow } from '@/styles/Containers';
import axios from 'axios';
import API_URL from '@/config';
import { modalStyles, GreyHrLine } from '@/styles/Modals';
import { CyanButton, ModalXButton, BlueButton } from '@/styles/Buttons';
import { RateCourseButton } from './RateCourseButton';
import { ScoreRadioButtonsBar } from './RadioButtonBar';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Loading from './Loading';
import { TestReview } from '@/models/review';
import CourseReviewSlider from './CourseReviewSlider';
import { CustomizableHeading } from '@/styles/Text';

interface ReviewFormProps {
  courseName: string;
  courseCode: string;
  reviewId: number;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setReviewId?: React.Dispatch<React.SetStateAction<number>>;
  myPage?: boolean;
  children?: ReactNode;
  reviewStatus: string;
  refetch?: () => void;
}
interface Review {
  id: number;
  course_code: string;
  score: number;
  workload: number;
  difficulty: number;
  review_text?: string;
  anonymous: boolean;
  in_draft: boolean;
}

const TextInput = styled.textarea`
  border: 1px solid rgba(69, 123, 157, 0.62);
  border-radius: 10px;
  width: 100%;
  min-height: 60px;
  box-sizing: border-box;
  margin: 5px 0 5px;
  padding: 8px;
`;

const InputDescription = styled.p`
  font-weight: bold;
  margin: 20px 0 5px 0;
`;

const ItalicText = styled.p`
  font-style: italic;
  font-size: 14px;
`;

const BoldInputDescription = styled.p`
  margin: 20px 0 10px 0;
  font-family: 'poppinsmedium';
  color: ${({ theme }) => theme.color.darkBlue};
`;

const BoldLabelDescription = styled.p`
  margin-bottom: 1rem;
  font-family: 'poppinsmedium';
  color: ${({ theme }) => theme.color.darkBlue};
`;

const SliderLabel = styled.p`
  position: relative;
  text-align: center;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5rem;
  margin-top: 3rem;
  > div {
    width: 100%;
  }
  @media (${({ theme }) => theme.device.tabletLaptop}) {
    flex-direction: column;
    gap: 0;
  }
`;

const BlackAnchor = styled.a`
  color: black;
`;

const GuidelineLink = styled(Link)`
  margin-top: 1rem;
  margin-top: 1vh;
  margin-top: 1rem;
  font-family: poppinsmedium;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
  color: ${({ theme }) => theme.color.darkBlue};
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0 10px;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
  box-sizing: border-box;
`;

const StyledCheckbox = styled.input`
  margin-right: 5px;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
`;

const DynamicPaddingDiv = styled.div`
  padding: 2rem;
  @media (${({ theme }) => theme.device.tablet}) {
    padding: 1rem;
  }
`;

const usePostReviewMutation = (onSuccess: () => void, courseCode: string, myPage: boolean, refetch: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['postReview'],
    async (review: Review) => {
      await axios.post(`${API_URL}/review/`, review);
    },
    {
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries(['review', courseCode]);
        if (myPage) {
          refetch();
        }
      },
    },
  );
};

const usePutReviewMutation = (onSuccess: () => void, courseCode: string, myPage: boolean, refetch: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['putReview'],
    async (review: Review) => {
      await axios.put(`${API_URL}/review/update/`, review);
    },
    {
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries(['review', courseCode]);
        if (myPage) {
          refetch();
        }
      },
    },
  );
};

const useReviewQuery = (
  reviewId: number,
  edit: boolean,
  setReviewText: React.Dispatch<React.SetStateAction<string>>,
  setDifficultyValue: React.Dispatch<React.SetStateAction<number>>,
  setScoreValue: React.Dispatch<React.SetStateAction<number>>,
  setWorkloadValue: React.Dispatch<React.SetStateAction<number>>,
  setAnonymous: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDraft: React.Dispatch<React.SetStateAction<boolean>>,
) =>
  useQuery<TestReview>(
    ['review', reviewId],
    async () => {
      return await axios.get(`${API_URL}/review/${reviewId}/`).then((res) => {
        return res.data;
      });
    },
    {
      onSuccess: (data) => {
        if (data.review_text) {
          setReviewText(data.review_text);
        }
        if (data.difficulty != undefined) {
          setDifficultyValue(data.difficulty);
        }
        if (data.score) {
          setScoreValue(data.score);
        }
        if (data.workload != undefined) {
          setWorkloadValue(data.workload);
        }
        if (data.anonymous) {
          setAnonymous(data.anonymous);
        }
        if (data.in_draft) {
          setIsDraft(data.in_draft);
        }
      },
      enabled: edit,
    },
  );

const ReviewForm: React.FC<ReviewFormProps> = ({ courseName, courseCode, reviewId, edit, setEdit, setReviewId, myPage, children, reviewStatus, refetch }) => {
  const [scoreValue, setScoreValue] = useState<number>(-1);
  const [difficultyValue, setDifficultyValue] = useState<number>(1);
  const [workloadValue, setWorkloadValue] = useState<number>(1);
  const [reviewText, setReviewText] = useState<string>('');
  const [isAnonymous, setAnonymous] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isOpen, setModalIsOpen] = useState<boolean>(false);
  const { isLoading } = useReviewQuery(reviewId, edit, setReviewText, setDifficultyValue, setScoreValue, setWorkloadValue, setAnonymous, setIsDraft);

  const toggleModalIsOpen = useCallback(() => {
    setEdit(false);
    setModalIsOpen((isOpen) => !isOpen);
    setDifficultyValue(1);
    setScoreValue(-1);
    setWorkloadValue(1);
    setReviewText('');
    setAnonymous(false);
  }, [setEdit]);

  const review: Review = {
    id: reviewId,
    course_code: courseCode,
    score: scoreValue,
    workload: workloadValue,
    difficulty: difficultyValue,
    review_text: reviewText,
    anonymous: isAnonymous,
    in_draft: isDraft,
  };

  const reviewModal = {
    overlay: {
      ...modalStyles.overlay,
    },
    content: {
      ...modalStyles.content,
      minWidth: '80vw',
      maxHeight: '80vh',
    },
  };

  const { mutate } = usePostReviewMutation(
    toggleModalIsOpen,
    review.course_code,
    myPage ?? false,
    refetch ??
      (() => {
        return null;
      }),
  );
  const putReviewData = usePutReviewMutation(
    toggleModalIsOpen,
    review.course_code,
    myPage ?? false,
    refetch ??
      (() => {
        return null;
      }),
  );

  function clickSend(review: Review) {
    putReviewData.mutate(review);
  }

  useEffect(() => {
    if (edit) {
      setModalIsOpen(true);
    }
  }, [edit]);

  const reviewExists = edit || review.in_draft;
  const hasChanges = difficultyValue !== 1 || workloadValue !== 1 || scoreValue !== -1 || reviewText !== '' || isAnonymous === true;
  return (
    <>
      {myPage ? (
        <SemesterRow
          status={reviewStatus}
          onClick={() => {
            setModalIsOpen(true);
            if (reviewStatus === 'published' || reviewStatus === 'draft') {
              setEdit(true);
            }
          }}
          title={`${courseCode} - ${courseName}`}
        >
          {children}
        </SemesterRow>
      ) : (
        <RateCourseButton onClickFunction={toggleModalIsOpen} courseCode={courseCode} edit={edit} setEdit={setEdit} setReviewId={setReviewId ?? (() => null)} />
      )}
      {isLoading && edit ? (
        <Loading />
      ) : (
        <Modal isOpen={isOpen} onRequestClose={toggleModalIsOpen} style={reviewModal} contentLabel='Review form modal'>
          <DynamicPaddingDiv>
            <FlexContainer justifyContent='space-between'>
              <CustomizableHeading fontSize='16px' margin='0 0 5px 0' headingLevel='h2'>
                {courseCode}
              </CustomizableHeading>
              <ModalXButton onClick={toggleModalIsOpen}>&#10006;</ModalXButton>
            </FlexContainer>
            <CustomizableHeading fontSize='22px' headingLevel='h1' option='bold'>
              {courseName}
            </CustomizableHeading>
            <GreyHrLine margin='15px 0 0 0' />
            <div>
              <BoldInputDescription>
                Totalvurdering <BlackAnchor>*</BlackAnchor>
              </BoldInputDescription>
              <ScoreRadioButtonsBar radioID='reviewScore' valueSetter={setScoreValue} value={scoreValue} valueVariable={1} />
            </div>

            <SliderContainer>
              <div>
                <BoldLabelDescription>
                  Vanskelighetsgrad <BlackAnchor>*</BlackAnchor>
                </BoldLabelDescription>
                <CourseReviewSlider
                  labelType='difficulty'
                  sliderValue={reviewExists ? review.difficulty : difficultyValue}
                  setSliderValue={setDifficultyValue}
                />
                <SliderLabel>Hvor vanskelig synes du at emnet var?</SliderLabel>
              </div>
              <div>
                <BoldLabelDescription>
                  Arbeidsmengde <BlackAnchor>*</BlackAnchor>
                </BoldLabelDescription>
                <CourseReviewSlider labelType='workload' sliderValue={reviewExists ? review.workload : workloadValue} setSliderValue={setWorkloadValue} />
                <SliderLabel>Hvor mye arbeid synes du faget var?</SliderLabel>
              </div>
            </SliderContainer>

            <InputDescription style={{ margin: '30px 0 0 0' }}>Kommentar</InputDescription>
            <TextInput value={reviewText} maxLength={750} onChange={(e) => setReviewText(e.target.value)} />
            <CheckboxContainer>
              <CheckboxLabel htmlFor='anonym'>
                <span style={{ fontSize: '1rem', marginRight: '5px' }}>Jeg vil være anonym:</span>
              </CheckboxLabel>
              <StyledCheckbox id='anonym' name='anonym' type='checkbox' checked={isAnonymous} onChange={(e) => setAnonymous(e.target.checked)} />
            </CheckboxContainer>
            <div style={{ display: 'flex', gap: 15 }}>
              {!reviewExists ? (
                <>
                  <BlueButton disabled={scoreValue === -1} onClick={() => mutate(review)}>
                    {scoreValue === -1 ? 'Mangler totalvurdering' : 'Publiser vurdering'}
                  </BlueButton>
                  <CyanButton disabled={!hasChanges} onClick={() => mutate({ ...review, in_draft: true })}>
                    Lagre som utkast
                  </CyanButton>
                </>
              ) : review.in_draft ? (
                <>
                  <BlueButton disabled={scoreValue === -1} onClick={() => clickSend({ ...review, in_draft: false })}>
                    {scoreValue === -1 ? 'Mangler totalvurdering' : 'Publiser vurdering'}
                  </BlueButton>
                  <CyanButton onClick={() => clickSend(review)}>Lagre utkastet</CyanButton>
                </>
              ) : (
                <BlueButton onClick={() => clickSend(review)}>Lagre endringer</BlueButton>
              )}
            </div>
            {isAnonymous ? (
              <ItalicText></ItalicText>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <GuidelineLink to='/guidelines'>Regler og tips for gode emnevurderinger</GuidelineLink>
                <ItalicText>
                  Merk at ditt fornavn og din studiekode kommer til å vises i vurderingen av emnet. Tjenesten støtter ikke personangrep mot fagstaben og slike
                  vurderinger vil bli slettet.
                </ItalicText>
              </div>
            )}
          </DynamicPaddingDiv>
        </Modal>
      )}
    </>
  );
};

export default ReviewForm;
