import React, { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import API_URL from '@/config';
import { Pagination } from '@/components/pagination/Pagination';
import Review from './review/Review';
import { EmptyResult } from './course/CourseList';
import Loading from './Loading';
import { usePaginationContext } from '@/context/PaginationContext';
import buildQueryString from '@/utils/buildQueryString';
import { useQuery } from '@tanstack/react-query';

const Wrapper = styled.div`
  padding: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

interface ReviewListProps {
  courseCode: string;
  limitReviews: boolean;
  numberOfReviewSetter: (value: number) => void;
  setReviewId: React.Dispatch<React.SetStateAction<number>>;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ReviewType {
  first_name: string;
  study_programme: string;
  score: number;
  workload: number | string | void;
  difficulty: number | string | void;
  review_text: string;
  date: string;
  can_delete: boolean;
  course_code: string;
  id: number;
  upvote_status: number;
  num_upvotes: number;
  report_status: number;
}

interface ReviewListResponse {
  total_count: number;
  reviews: ReviewType[];
  is_admin: boolean;
}

const useReviewListQuery = (courseCode: string, limitReviews = false, numberOfReviewSetter: (nReviews: number) => void, PAGE_SIZE = 5) => {
  const { reviewPagination, setReviewPagination } = usePaginationContext();

  // this useEffect is used for resetting page number to 1 on clean up
  // dependency array must be empty to not reset the page on when pageprovider changes
  useEffect(() => {
    return () => {
      setReviewPagination((pagination) => ({ ...pagination, currentPage: 1 }));
    };
  }, [setReviewPagination]);

  const queryObj = {
    n: PAGE_SIZE,
    course_code: courseCode,
    offset: (reviewPagination.currentPage - 1) * PAGE_SIZE,
    showMyProgramme: String(limitReviews),
  };

  return useQuery<ReviewListResponse>(['review', courseCode, queryObj], async () => {
    const response = await axios.get(`${API_URL}/review/get/${buildQueryString(queryObj)}`);
    const { total_count } = response.data as ReviewListResponse;
    setReviewPagination((pagination) => ({ ...pagination, totalPages: Math.ceil(total_count / PAGE_SIZE) }));
    numberOfReviewSetter(total_count);
    return response.data;
  });
};

const ReviewList: React.FC<ReviewListProps> = ({ courseCode, limitReviews, numberOfReviewSetter, setReviewId, setEdit }) => {
  const { isLoading, isError, data } = useReviewListQuery(courseCode, limitReviews, numberOfReviewSetter);
  const reviews: ReviewType[] = data?.reviews ?? [];

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div style={{ margin: '3rem auto' }}>Noe gikk galt...</div>;
  }
  return (
    <Wrapper>
      {reviews.length ? (
        <>
          {reviews.map((review) => {
            return (
              <Review
                key={review.first_name + review.date}
                name={review.first_name}
                studyProgramme={review.study_programme}
                score={review.score}
                workLoad={review.workload === -1 ? 'Not given' : review.workload}
                difficulty={review.difficulty === -1 ? 'Not given' : review.difficulty}
                text={review.review_text}
                date={review.date}
                isAdmin={data?.is_admin ?? false}
                canDelete={review.can_delete}
                id={review.id}
                upvoteStatus={review.upvote_status}
                numUpvotes={review.num_upvotes}
                reportStatus={review.report_status}
                setReviewId={setReviewId}
                setEdit={setEdit}
              />
            );
          })}
          <Pagination currentPage='reviews' />
        </>
      ) : (
        <EmptyResult>Ingen vurderinger av {courseCode}. </EmptyResult>
      )}
    </Wrapper>
  );
};

export default ReviewList;
