import { InformationCarousel } from '@/components/Carousel/InformationCarousel';
import { CourseList } from '@/components/course/CourseList';
import Footer from '@/components/Footer';
import Navbar from '@/components/navbar/Navbar';
import PrivacyPopUp from '@/components/PrivacyPopUp';
import { default as API_URL } from '@/config';
import { CustomizableHeading } from '@/styles/Text';
import { useAuth } from '@/utils/auth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';

const CourseListContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem 20% 4rem 20%;
  @media ${({ theme }) => theme.device.laptop} {
    padding: 1rem 1% 4rem 1%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 1rem 2% 4rem 2%;
  }
`;

const usePrivacyQuery = (onSuccess: (data: string) => void) => {
  const { isNotAuthenticated } = useAuth();

  useQuery(
    ['privacy'],
    async () => {
      if (isNotAuthenticated) {
        return 'User is not authenticated';
      }
      const response = await axios.get(`${API_URL}/user/check_privacy_policy/`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data !== 'User is not authenticated') {
          onSuccess(data);
        }
      },
    },
  );
};

const FrontPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSuccess = (data: string) => {
    if (data === 'User has not read the privacy policy') {
      setIsOpen(true);
    }
    if (data === 'User is not logged in' || data === 'User is not authenticated') {
      setIsOpen(false);
    }
  };

  usePrivacyQuery(handleSuccess);

  return (
    <>
      <PrivacyPopUp isOpen={isOpen} handleCloseModal={setIsOpen} />
      <Navbar />

      <InformationCarousel />
      <CourseListContainer>
        <CustomizableHeading headingLevel='h1' fontSize='2em' textAlign='center'>
          De mest populære emnene
        </CustomizableHeading>
        <CourseList />
      </CourseListContainer>
      <Footer />
    </>
  );
};

export default FrontPage;
