import { defaultTheme } from './theme';
import styled from 'styled-components';
import { HrLine } from '@/styles/Containers';

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: defaultTheme.color.white,
    border: `0.5px solid ${defaultTheme.color.darkBlue}`,
    borderRadius: '40px',
    minWidth: '40vw',
    maxWidth: '80vw',
    maxHeight: '75vh',
    padding: '20px 30px',
  },
};

export const GreyHrLine = styled(HrLine)`
  border-bottom: 1px solid ${defaultTheme.color.lightGrey};
`;
