import React, { useState } from 'react';
import { MePageCourseItemText } from '../../styles/Text';
import { UnavailableSemesterRow } from '@/styles/Containers';
import ReviewForm from '../ReviewForm';

export interface MyCourseProps {
  courseName: string;
  courseCode: string;
  yourScore: string;
  reviewStatus: string;
  unavailable?: boolean;
  review_id: number;
  refetch?: () => void;
}

const MyCourse = ({ courseName, courseCode, yourScore, reviewStatus, unavailable, review_id, refetch }: MyCourseProps) => {
  const [edit, setEdit] = useState<boolean>(false);

  if (unavailable) {
    return (
      <UnavailableSemesterRow title={`${courseCode} - ${courseName}`}>
        <MePageCourseItemText textAlign='left'>{courseCode}</MePageCourseItemText>
        <MePageCourseItemText ellipsis textAlign='left'>
          {courseName}
        </MePageCourseItemText>
        <MePageCourseItemText ellipsis textAlign='center'>
          Utilgjengelig
        </MePageCourseItemText>
      </UnavailableSemesterRow>
    );
  }
  return (
    <ReviewForm
      courseCode={courseCode}
      courseName={courseName}
      reviewId={review_id}
      edit={edit}
      setEdit={setEdit}
      reviewStatus={reviewStatus}
      refetch={refetch}
      myPage
    >
      <MePageCourseItemText textAlign='left'>{courseCode}</MePageCourseItemText>
      <MePageCourseItemText ellipsis textAlign='left'>
        {courseName}
      </MePageCourseItemText>
      {reviewStatus === 'published' ? (
        <MePageCourseItemText textAlign='center'>{yourScore}</MePageCourseItemText>
      ) : reviewStatus === 'draft' ? (
        <MePageCourseItemText ellipsis textAlign='center' underlined>
          Fullfør vurdering
        </MePageCourseItemText>
      ) : (
        <MePageCourseItemText ellipsis textAlign='center' underlined>
          Gi vurdering
        </MePageCourseItemText>
      )}
    </ReviewForm>
  );
};

export default MyCourse;
