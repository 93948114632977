import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSize from '@/utils/useSize';
import PaginationButton from './PaginationButton';
import arrow from '@/assets/icons/arrow.svg';
import arrows from '@/assets/icons/arrows.svg';
import { usePaginationContext } from '@/context/PaginationContext';

interface PaginationProps {
  currentPage: string;
}

const PaginationWrapper = styled.div`
  padding: 4rem 0 4rem 0;
  display: flex;
  justify-content: center;
  gap: 0.6rem;
`;

export const PaginationComponent: React.FC<PaginationProps> = ({ currentPage }) => {
  const { coursePagination, setCoursePagination, reviewPagination, setReviewPagination } = usePaginationContext();

  const [page, setPage] = useState<number>(coursePagination.currentPage);
  const [totalPages, setTotalPages] = useState<number>(coursePagination.totalPages);

  const { isMobile } = useSize();

  useEffect(() => {
    if (currentPage === 'courses') {
      setPage(coursePagination.currentPage);
      setTotalPages(coursePagination.totalPages);
    }
    if (currentPage === 'reviews') {
      setPage(reviewPagination.currentPage);
      setTotalPages(reviewPagination.totalPages);
    }
  }, [currentPage, coursePagination, reviewPagination]);

  const setCurrentPage = (pageNumber: number) => {
    if (currentPage === 'courses') {
      setCoursePagination((pagination) => ({ ...pagination, currentPage: pageNumber }));
    }
    if (currentPage === 'reviews') {
      setReviewPagination((pagination) => ({ ...pagination, currentPage: pageNumber }));
    }
  };

  if (!totalPages) {
    return <PaginationWrapper />;
  }

  return (
    <PaginationWrapper>
      {page > 2 && totalPages > 3 && (
        <PaginationButton onClick={() => setCurrentPage(1)}>
          <img src={arrows} style={{ transform: 'rotate(180deg)' }} />
        </PaginationButton>
      )}
      {page !== 1 && !isMobile && (
        <PaginationButton onClick={() => setCurrentPage(page - 1)}>
          <img src={arrow} style={{ transform: 'rotate(180deg)' }} />
        </PaginationButton>
      )}
      {page === totalPages && totalPages > 3 && <PaginationButton onClick={() => setCurrentPage(page - 3)}>{page - 3}</PaginationButton>}
      {page > totalPages - 2 && page > 2 && totalPages > 2 && <PaginationButton onClick={() => setCurrentPage(page - 2)}>{page - 2}</PaginationButton>}
      {page > 1 && totalPages > 1 && <PaginationButton onClick={() => setCurrentPage(page - 1)}>{page - 1}</PaginationButton>}
      <PaginationButton active onClick={() => null}>
        {page}
      </PaginationButton>
      {page < totalPages && <PaginationButton onClick={() => setCurrentPage(page + 1)}>{page + 1}</PaginationButton>}
      {page < totalPages - 1 && totalPages > 2 && <PaginationButton onClick={() => setCurrentPage(page + 2)}>{page + 2}</PaginationButton>}
      {page === 1 && totalPages > 3 && <PaginationButton onClick={() => setCurrentPage(page + 3)}>{page + 3}</PaginationButton>}
      {page !== totalPages && totalPages > 1 && !isMobile && (
        <PaginationButton onClick={() => setCurrentPage(page + 1)}>
          <img src={arrow} />
        </PaginationButton>
      )}

      {page < totalPages - 2 && totalPages !== 1 && (
        <PaginationButton onClick={() => setCurrentPage(totalPages)}>
          <img src={arrows} />
        </PaginationButton>
      )}
    </PaginationWrapper>
  );
};
export const Pagination = PaginationComponent;
