import React from 'react';
import styled from 'styled-components';

const EmailContainer = styled.div`
  text-align: center;
`;

const LoginHelpEmail: React.FC = () => {
  return (
    <EmailContainer>
      <p>
        Får ikke logget inn? Kontakt oss på: <a href='mailTo:hjelp@emnr.no'>hjelp@emnr.no</a>
      </p>
    </EmailContainer>
  );
};

export default LoginHelpEmail;
