import React from 'react';
import { CenteredFlexContainer, ShapeContainer } from '@/styles/Containers';
import Layout from '@/styles/Layout';
import { CustomizableHeading, StyledParagraph } from '@/styles/Text';

import { Circle, RotatedSquare } from '@/styles/Shapes';
import { defaultTheme } from '@/styles/theme';
import wictor from '@/assets/images/wic.jpg';
import casp from '@/assets/images/casp.png';
import eivind from '@/assets/images/eivind.jpg';
import haakon from '@/assets/images/haakon.png';
import harald from '@/assets/images/harald.png';
import jakob from '@/assets/images/jakob.png';
import sylvi from '@/assets/images/sylvi.png';
import blank from '@/assets/images/blank.png';
import Person from '@/components/Person';

const AboutPage: React.FC = () => {
  return (
    <Layout padding='0 20%'>
      <ShapeContainer>
        <Circle color={defaultTheme.color.lightBlue} size='240px' right='0' top='130px' margin='0 -170px 0 0' mobileMargin='0 -400px 0 0' />
        <RotatedSquare color={defaultTheme.color.blue} size='240px' left='0' top='50vh' angle='20deg' margin='0 0 0 -200px' mobileMargin='0 0 0 -400px' />
        <RotatedSquare color={defaultTheme.color.blue} size='240px' right='0' top='75vh' angle='80deg' margin='0 -225px 0 0' mobileMargin='0 -400px 0 0' />
      </ShapeContainer>
      <CenteredFlexContainer flexDirection='column' margin='5% 10%'>
        <CustomizableHeading headingLevel='h1' option='bold' mobileFontSize='28px'>
          Om EMNR
        </CustomizableHeading>
        <StyledParagraph>
          EMNR hjelper studenter med å velge emner på NTNU. Brukere har tilgang til relevant objektiv informasjon som snittkarakter og ståprosent, i tillegg er
          det subjektive emneanmeldelser laget av andre studenter.
          <br />
          <br />
          Brukere blir autentisert via feide og dermed er det kun studenter som har hatt emnet tidligere som har mulighet til å anmelde faget. På denne måten
          kan brukere være sikre på at anmeldelsene er ekte.
          <br />
          <br />
          EMNR er laget for og av studenter.
        </StyledParagraph>
        <CustomizableHeading headingLevel='h2' option='bold' mobileFontSize='28px'>
          Studentmediene
        </CustomizableHeading>
        <StyledParagraph>
          EMNR blir nå utviklet av Studentmediene i Trondheim AS.
          <br />
          Vi kan kontaktes på mail: <a href='mailTo:hjelp@emnr.no'>hjelp@emnr.no</a>
        </StyledParagraph>
        <CustomizableHeading headingLevel='h2' mobileFontSize='28px' option='bold'>
          De frivillige som driver EMNR
        </CustomizableHeading>
        <CenteredFlexContainer flexWrap='wrap' gap='1rem'>
          <Person name='Dana Yerbolat' role='UI/UX-designer' imgSrc={blank} linkedIn='https://www.linkedin.com/in/yerbolat-dana-0b15991a0/' />
          <Person name='Niklas Unneland' role='IT-Utvikler' imgSrc={blank} linkedIn='https://www.linkedin.com/in/niklas-unneland-410535250/' />
          <Person name='Christopher Høe' role='IT-Utvikler' imgSrc={blank} linkedIn='https://www.linkedin.com/in/christopher-h%C3%B8e-3b7924257/' />
          <Person name='Kine Kai Moe Holthe' role='IT-Utvikler' imgSrc={blank} linkedIn={'https://www.linkedin.com/in/kine-kai-moe-holthe-44a16b1b6/'} />
          <Person name='Erik Menkin Lysfjord' role='IT-Utvikler' imgSrc={blank} linkedIn={'https://www.linkedin.com/in/erik-menkin-lysfjord/'} />
          <Person name='Benjamin Zubača' role='IT-Utvikler' imgSrc={blank} linkedIn={'https://www.linkedin.com/in/benjamin-zubača-30968724a'} />
          <Person name='Iqra Arshad' role='IT-Utvikler' imgSrc={blank} linkedIn={'https://www.linkedin.com/in/iqra-arshad-77892b263/'} />
          <Person name='Eirik Slettan' role='IT-Utvikler' imgSrc={blank} linkedIn={'https://www.linkedin.com/in/eirik-slettan-1b5ab3128/'} />
          <Person name='Søren Boucher' role='IT-Utvikler' imgSrc={blank} linkedIn={'https://www.linkedin.com/in/soren-b-99b4212b4/'} />
        </CenteredFlexContainer>
        <br />
        <br />
        <CustomizableHeading headingLevel='h2' mobileFontSize='28px' option='bold'>
          Det originale teamet
        </CustomizableHeading>
        <StyledParagraph>Teamet bestod av 7 studenter som studerte Datateknologi ved NTNU.</StyledParagraph>
        <CenteredFlexContainer flexWrap='wrap' gap='1rem'>
          <Person name='Haakon Gunleiksrud' imgSrc={haakon} linkedIn='https://www.linkedin.com/in/haakogun/' />
          <Person name='Eivind H. Furdal' imgSrc={eivind} linkedIn='https://www.linkedin.com/in/eivindhf/' />
          <Person name='Jakob E. Kielland' imgSrc={jakob} linkedIn='https://www.linkedin.com/in/jakobkielland/' />
          <Person name='Sylvi P. Huynh' imgSrc={sylvi} linkedIn='https://www.linkedin.com/in/sylvi-huynh-207401200/' />
          <Person name='Harald G. Brevik' imgSrc={harald} linkedIn='https://www.linkedin.com/in/harald-guntvedt-brevik-6386a3169/' />
          <Person name='Casper Feng' imgSrc={casp} linkedIn='https://www.linkedin.com/in/casperfeng/' />
          <Person name='Wictor Zhao' imgSrc={wictor} linkedIn='https://www.linkedin.com/in/wictorz/' />
        </CenteredFlexContainer>
      </CenteredFlexContainer>
    </Layout>
  );
};

export default AboutPage;
