import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { NavbarButton } from '@/styles/Buttons';
import useSize from '@/utils/useSize';
import Searchbar from './Searchbar';
import Searchfield from './Searchfield';
import DropdownCustom from './DropdownCustom';
import 'react-dropdown/style.css';
import '@/utils/dropdown.css';

const SearchDescriptionContainer = styled.div`
  flex: 0 0 60%;
  font-size: 0.9rem;
`;
const SortDescriptionContainer = styled.div`
  flex: 0 0 40%;
  font-size: 0.9rem;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const SortingContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.lightlightGrey};
  border-radius: 15px;
  @media (${({ theme }) => theme.device.tablet}) {
    background-color: ${({ theme }) => theme.color.white};
    justify-content: space-between;
  }
`;

const NavBarButtonContainer = styled.div`
  flex: 0 0 10%;
  display: flex;
  @media (${({ theme }) => theme.device.tablet}) {
    flex: 0 0 27%;
  }
`;

const SearchContainerOutline = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  text-align: left;
  border-radius: 30px;
  margin: 2rem 20% 0;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  transition: max-height 400ms ease;

  @media ${({ theme }) => theme.device.laptop} {
    margin: 2rem 10% 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 20px;
    margin: 2rem 5%;
  }
`;

interface InputProps {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setSortOption: React.Dispatch<React.SetStateAction<string>>;
  sortOption: string;
  searchFieldChanged: boolean;
  setSearchFieldChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchContainer = ({ setInputValue, inputValue, setSortOption, sortOption, searchFieldChanged, setSearchFieldChanged }: InputProps) => {
  const { isMobile, size } = useSize();

  const navigate = useNavigate();
  const isOnSearchPage = useLocation().pathname === '/search';
  const isOnFrontPage = useLocation().pathname === '/';
  const isOnMePage = useLocation().pathname === '/me';
  const isOnCoursePage = decodeURIComponent(useLocation().pathname.substring(8));

  const isNotOnFrontPage = isOnMePage || isOnSearchPage || isOnCoursePage;
  const submitSearch = useCallback(() => {
    navigate({
      pathname: '/search',
      search: `?inputValue=${inputValue}&sortOption=${sortOption}`,
    });
  }, [inputValue, navigate, sortOption]);

  if (isMobile && isOnFrontPage) {
    return (
      <SearchContainerOutline>
        <SearchDescriptionContainer>Søk etter fag eller emnekode</SearchDescriptionContainer>
        <Searchfield
          seperated
          setInputValue={setInputValue}
          onEnterKeyPress={submitSearch}
          inputValue={inputValue}
          setSearchFieldChanged={setSearchFieldChanged}
          searchFieldChanged={searchFieldChanged}
        />

        <SearchDescriptionContainer>Sortering</SearchDescriptionContainer>
        <SortingContainer>
          <DropdownCustom setSortOption={setSortOption} sortOption={sortOption} />
          <NavBarButtonContainer>
            <NavbarButton onClick={submitSearch}>Søk</NavbarButton>
          </NavBarButtonContainer>
        </SortingContainer>
      </SearchContainerOutline>
    );
  }

  if (isMobile && isNotOnFrontPage) {
    return (
      <SearchContainerOutline
        style={{
          marginTop: 0,
          background: '#1D3557',
          paddingTop: 0,
          marginBottom: 0,
        }}
      >
        <Searchfield
          seperated
          setInputValue={setInputValue}
          onEnterKeyPress={submitSearch}
          inputValue={inputValue}
          setSearchFieldChanged={setSearchFieldChanged}
          searchFieldChanged={searchFieldChanged}
        />
        <SortingContainer
          style={{
            background: '#1D3557',
            marginTop: '0.2em',
          }}
        >
          <DropdownCustom setSortOption={setSortOption} sortOption={sortOption} />
          <NavBarButtonContainer>
            <NavbarButton onClick={submitSearch}>Søk</NavbarButton>
          </NavBarButtonContainer>
        </SortingContainer>
      </SearchContainerOutline>
    );
  }

  if (!isMobile && isNotOnFrontPage) {
    return (
      <SearchContainerOutline
        style={{
          margin: 0,
          background: '#1D3557',
          padding: 0,
          width: size?.width != undefined && size?.width > 1007 ? '60%' : '60%',
          marginRight: size?.width != undefined && size?.width > 1007 ? '100px' : '200px',
        }}
      >
        <Searchbar
          setSearchFieldChanged={setSearchFieldChanged}
          searchFieldChanged={searchFieldChanged}
          setInputValue={setInputValue}
          inputValue={inputValue}
          setSortOption={setSortOption}
          sortOption={sortOption}
        />
      </SearchContainerOutline>
    );
  }

  return (
    <SearchContainerOutline>
      <RowFlex>
        {<SearchDescriptionContainer>Søk etter fag eller emnekode</SearchDescriptionContainer>}
        <SortDescriptionContainer>Sortering</SortDescriptionContainer>
      </RowFlex>

      <Searchbar
        setSearchFieldChanged={setSearchFieldChanged}
        searchFieldChanged={searchFieldChanged}
        setInputValue={setInputValue}
        inputValue={inputValue}
        setSortOption={setSortOption}
        sortOption={sortOption}
      />
    </SearchContainerOutline>
  );
};

export default SearchContainer;
