import { useState, useEffect } from 'react';

type Size = {
  width: number;
  height: number;
};

export const useSize = (breakpoint = 768) => {
  const [size, setSize] = useState<Size | null>(null);
  const isMobile = size === null ? undefined : size.width <= breakpoint;

  useEffect(() => {
    const updateWindowDimensions = () => setSize({ width: window.innerWidth, height: window.innerHeight });

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  return { isMobile, size };
};

export default useSize;
