import React from 'react';
import Layout from '@/styles/Layout';
import PageError from '@/components/PageError';

const MePage = () => {
  return (
    <Layout padding='0 20%'>
      <PageError />
    </Layout>
  );
};

export default MePage;
