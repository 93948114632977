import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import bars from '../assets/icons/bars.svg';
import infoOutline from '../assets/icons/info_outline.svg';
import paperPlane from '../assets/icons/paper-plane.svg';
import shield from '../assets/icons/shieldQuestion.svg';
import emnrLogo from '../assets/images/emnr_no_text.svg';

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.color.darkBlue};
  z-index: 2;
  height: auto;
  color: #f1faee;
  bottom: 0;
`;

const FooterGrid = styled.div`
  display: grid;
  padding: 3rem 0;
  width: 60%;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'left-top logo right-top'
    'links-container none contact-container'
    'line-w-emnr line-w-emnr line-w-emnr'
    'left-bottom bottom-text right-bottom';
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'links-container'
      'line-top'
      'contact-container'
      'line-bottom'
      'logo'
      'bottom-text';
    width: 80%;
    gap: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.light};
  cursor: pointer;
  &:hover {
    color: rgb(168, 218, 220);
  }
`;

const Logo = styled.img`
  grid-area: logo;
  width: 4rem;
  margin: 0 auto;
  padding-bottom: 0.3rem;
`;

const Icon = styled.img`
  width: 1.25rem;
  max-height: 1.25rem;
  margin: 0;
`;

const FooterLabel = styled.div`
  font-size: 0.9rem;
  grid-area: line-w-emnr;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  align-content: center;
`;

const FooterLine = styled.hr`
  color: #ffffff;
  display: inline-block;
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: 1px;
  margin: 0;
`;

const BottomText = styled.p`
  font-size: 0.9rem;
  grid-area: bottom-text;
  font-weight: normal;
  margin: 0.3rem 0 0 0;
  text-align: center;
`;

const EmnrText = styled.p`
  font-size: 1.5rem;
  grid-area: emnr;
  font-weight: 1000;
  margin: 0.25rem 1rem;
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

const FooterInfo = styled.span`
  font-size: 1.1rem;
  align-items: center;
  color: #ffffff;
  display: flex;
  gap: 0.75rem;
  width: 100%;
`;

const LinksContainer = styled.div`
  font-size: 1.25rem;
  grid-area: links-container;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ContactContainer = styled.div`
  font-size: 1.25rem;
  grid-area: contact-container;
  display: flex;
  align-items: flex-end;
  justify-self: end;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-end;
  @media ${({ theme }) => theme.device.tablet} {
    justify-self: start;
  }
`;

const FooterHeader = styled.span`
  font-size: 1.25rem;
  font-weight: 900;
`;

const MailLink = styled.a`
  text-decoration: none;
  color: #ffffff;
  &:hover {
    color: rgb(168, 218, 220);
  }
`;

const Scroll = () => window.scrollTo({ top: 0, left: 0 });

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterGrid>
        <Logo src={emnrLogo} alt='emnr.no-logo' />
        <LinksContainer>
          <FooterInfo>
            <FooterHeader>EMNR</FooterHeader>
          </FooterInfo>
          <FooterInfo>
            <Icon src={bars} alt='three bars stacked icon' />
            <StyledLink to='/guidelines' onClick={Scroll}>
              Regler for bruk av siden
            </StyledLink>
          </FooterInfo>
          <FooterInfo>
            <Icon src={infoOutline} alt='Circle info icon' />
            <StyledLink to='/about' onClick={Scroll}>
              Om EMNR
            </StyledLink>
          </FooterInfo>
          <FooterInfo>
            <Icon src={shield} alt='Shield icon' />
            <StyledLink to='/privacy' onClick={Scroll}>
              Personvern
            </StyledLink>
          </FooterInfo>
        </LinksContainer>
        <FooterLine style={{ gridArea: 'line-top' }} />
        <ContactContainer>
          <FooterInfo>
            <FooterHeader>Kontakt</FooterHeader>
          </FooterInfo>
          <FooterInfo>
            <Icon src={paperPlane} alt='Send icon' />
            <MailLink href='mailTo:hjelp@emnr.no'>hjelp@emnr.no</MailLink>
          </FooterInfo>
        </ContactContainer>
        <FooterLabel>
          <FooterLine />
          <EmnrText>EMNR</EmnrText>
          <FooterLine />
        </FooterLabel>
        <FooterLine style={{ gridArea: 'line-bottom' }} />
        <BottomText>Studentmediene AS</BottomText>
      </FooterGrid>
    </FooterContainer>
  );
};

export default Footer;
