import React from 'react';
import styled from 'styled-components';
import { ExtraBold } from '@/styles/Text';
import InfoSlider from './InfoSlider';

interface CourseInfoProps {
  difficulty: number;
  workload: number;
  averageGrade: number;
  passRate: number;
  hasReview: boolean;
  averageGradeLetter?: string;
  // TODO: find out what to do with this
  // gradeDistribution: [number, number, number, number, number, number]; // There is probably a better way to do this (specify array of 6 numbers)
}

const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.color.lightlightBlue};
  border: solid 1px ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
`;

const InfoText = styled.p`
  font-size: 0.9em;
  font-weight: normal;
  font-family: poppinsregular;
  margin: 10px 0 0 0;
`;

const CourseInfoBox: React.FC<CourseInfoProps> = ({ difficulty, workload, averageGrade, averageGradeLetter, passRate, hasReview }) => {
  return (
    <InfoContainer>
      <ExtraBold>Vanskelighetsgrad:</ExtraBold>
      <InfoSlider sliderProgress={difficulty / 2} hasReview={hasReview} />
      <ExtraBold>Arbeidsmengde:</ExtraBold>
      <InfoSlider sliderProgress={workload / 2} hasReview={hasReview} />
      <ExtraBold>Karakterer for faget:</ExtraBold>
      <InfoText>
        Karaktersnitt: <ExtraBold>{averageGrade > 0 ? `${averageGrade.toFixed(1)} (${averageGradeLetter})` : 'Ikke tilgjengelig'}</ExtraBold>
      </InfoText>
      <InfoText>
        Ståprosent: <ExtraBold>{passRate}%</ExtraBold>
      </InfoText>
      {/* <InfoText>Karakterfordeling:</InfoText> //TODO: Implement frontend for grade distribution */}
    </InfoContainer>
  );
};

export default CourseInfoBox;
