import React from 'react';
import Navbar from '@/components/navbar/Navbar';
import styled from 'styled-components';
import Footer from '@/components/Footer';

interface LayoutProps {
  padding?: string;
}

const Body = styled.main`
  margin: 0;
  min-height: calc(100vh - 340px - 60px); //100vh - footer height - navbar height
  display: flex;
  flex-direction: column;
  padding: ${(props: LayoutProps) => props.padding || '0 20%'}; /* Flex wraps incorrectly if padding is 0 */
  @media${({ theme }) => theme.device.laptop} {
    padding: 0 1%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 2%;
  }
`;

const Layout: React.FC<LayoutProps> = ({ children, padding }) => {
  return (
    <>
      <Navbar />
      <Body padding={padding}>{children}</Body>
      <Footer />
    </>
  );
};

export default Layout;
