import React, { useState } from 'react';
import { SemesterContainer, SemesterCoursesContainer, SemesterRowHeader } from '@/styles/Containers';
import { CustomizableHeading, MePageCourseItemTextHeader } from '@/styles/Text';
import ExpandMore from '../../assets/icons/ExpandMore.svg';
import ExpandLess from '../../assets/icons/ExpandLess.svg';
import MyCourse from './MyCourse';
import styled from 'styled-components';

type SemesterProps = {
  semester: string;
  courses: MyCourse[];
  refetch: () => void;
};

export type MyCourse = {
  course_name_norwegian: string;
  course_code: string;
  review_status: string;
  my_review_score: string;
  semester: string;
  unavailable?: boolean;
  review_id: number;
};

const CollapseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  background-color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
`;
const TopRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
`;

export const Semester = ({ semester, courses, refetch }: SemesterProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <SemesterContainer margin='5vh 0'>
      <TopRow>
        <CollapseButton onClick={() => setIsExpanded(!isExpanded)}>
          <img src={isExpanded ? ExpandLess : ExpandMore} />
        </CollapseButton>
        <CustomizableHeading fontSize='1.5rem' headingLevel='h2' option='bold'>
          {semester}
        </CustomizableHeading>
      </TopRow>

      {isExpanded && (
        <SemesterRowHeader>
          <MePageCourseItemTextHeader as={'p'}>Emnekode</MePageCourseItemTextHeader>
          <MePageCourseItemTextHeader as={'p'}>Emnenavn</MePageCourseItemTextHeader>
          <MePageCourseItemTextHeader ellipsis as={'p'} textAlign={'center'}>
            Din vurdering
          </MePageCourseItemTextHeader>
        </SemesterRowHeader>
      )}
      <SemesterCoursesContainer>
        {isExpanded &&
          courses.map((currentCourse) => {
            return (
              <MyCourse
                courseCode={currentCourse.course_code}
                courseName={currentCourse.course_name_norwegian}
                reviewStatus={currentCourse.review_status}
                yourScore={`${currentCourse.my_review_score} / 5`}
                key={currentCourse.course_code}
                unavailable={currentCourse.unavailable}
                review_id={currentCourse.review_id}
                refetch={refetch}
              />
            );
          })}
      </SemesterCoursesContainer>
    </SemesterContainer>
  );
};
