import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface StyledSliderProps {
  sliderValue?: number;
  setSliderValue?: React.Dispatch<React.SetStateAction<number>>;
  labelType?: 'workload' | 'difficulty';
}

const StyledSlider = styled.input<StyledSliderProps>`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 25px;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  transition: background 450ms ease-in;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    background: #457b9d;
    border-radius: 100%;
  }

  &::-moz-range-thumb {
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    background: #457b9d;
    border-radius: 100%;
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Label = styled.p`
  bottom: 2rem;
  text-align: center;
  font-weight: bold;
`;

const CourseReviewSlider = ({ setSliderValue, sliderValue, labelType }: StyledSliderProps) => {
  const labels = {
    firstStep: labelType == 'difficulty' ? 'Lett' : 'Lite',
    secondStep: 'Middels',
    thirdStep: labelType == 'difficulty' ? 'Vanskelig' : 'Mye',
  };
  const sliderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const setInitialBackground = () => {
      if (sliderRef.current) {
        if (sliderValue !== undefined) {
          sliderRef.current.style.background = `linear-gradient(to right, rgba(69, 123, 157, 0.62) 0%, rgba(69, 123, 157, 0.62) ${
            (sliderValue / 2) * 100
          }%, #d3e6f2 ${(sliderValue / 2) * 100}%, #d3e6f2 100%)`;
        }
      }
    };

    setInitialBackground();
  }, [sliderValue]);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    if (setSliderValue) {
      setSliderValue(newValue);
    }

    event.target.style.background =
      'linear-gradient(to right, rgba(69, 123, 157, 0.62) 0%, rgba(69, 123, 157, 0.62)' +
      newValue * 50 +
      '%, #d3e6f2  ' +
      (newValue - 1) * 50 +
      '%,  #d3e6f2 100%)';
  };
  return (
    <div>
      <LabelContainer>
        <Label>{labels.firstStep}</Label>
        <Label>{labels.secondStep}</Label>
        <Label>{labels.thirdStep}</Label>
      </LabelContainer>
      <StyledSlider type='range' min='0' max='2' value={sliderValue} onChange={handleSliderChange} ref={sliderRef} />
    </div>
  );
};

export default CourseReviewSlider;
