import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import TrashIcon from '@/assets/icons/delete.svg';
import Modal from 'react-modal';
import { modalStyles } from '@/styles/Modals';
import { FlexContainer, HrLine } from '@/styles/Containers';
import { CustomizableHeading } from '@/styles/Text';
import { ModalXButton, RedButton } from '@/styles/Buttons';
import axios from 'axios';
import API_URL from '@/config';
import { PulseLoader } from 'react-spinners';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const TrashBtn = styled.img`
  width: 30px;
  margin-left: auto;
  cursor: pointer;
  :hover {
    filter: invert(48%) sepia(96%) saturate(3381%) hue-rotate(328deg) brightness(86%) contrast(112%);
  }
`;

interface ReviewProps {
  reviewId: number;
}

const useDeleteReviewMutation = (handleSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (reviewId: number) => {
      await axios.delete(`${API_URL}/review/delete/?reviewId=${reviewId}`);
    },
    {
      onSuccess: () => {
        handleSuccess();
        queryClient.invalidateQueries(['review']);
      },
    },
  );
};

const DeleteReview: React.FC<ReviewProps> = ({ reviewId }) => {
  const [isOpen, setModalIsOpen] = useState<boolean>(false);

  const toggleModalIsOpen = useCallback(() => {
    setModalIsOpen((isOpen) => !isOpen);
  }, [setModalIsOpen]);

  const { mutate, isLoading } = useDeleteReviewMutation(toggleModalIsOpen);

  Modal.setAppElement('#root');

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={toggleModalIsOpen} style={modalStyles} contentLabel='Delete Review Modal'>
        <FlexContainer justifyContent='space-between'>
          <CustomizableHeading headingLevel='h1' option='bold' margin='0 0 5px 0'>
            Slett anmeldelsen
          </CustomizableHeading>
          <ModalXButton onClick={toggleModalIsOpen}>&#10006;</ModalXButton>
        </FlexContainer>
        <HrLine margin='5px 0 15px 0' />
        <p>Denne anmeldelsen vil bli slettet.</p>
        <p>Er du sikker? Denne handlingen kan ikke bli reversert.</p>

        {isLoading ? (
          <RedButton disabled>
            <PulseLoader color='#FFF' size='10px' />
          </RedButton>
        ) : (
          <RedButton onClick={() => mutate(reviewId)}>Ja, jeg er sikker</RedButton>
        )}
      </Modal>
      <TrashBtn src={TrashIcon} onClick={toggleModalIsOpen} />
    </>
  );
};

export default DeleteReview;
