import Loading from '@/components/Loading';
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAuth, useVerifyFeideLoginCodeQuery } from '@/utils/auth';

const VerifyLogin: React.FC = () => {
  const { isAuthenticated, isNotAuthenticated } = useAuth();
  const [previousLocation, setPreviousLocation] = useState<string>('/');

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || '';
  useVerifyFeideLoginCodeQuery(code, isAuthenticated);

  useEffect(() => {
    setPreviousLocation(sessionStorage.getItem('previousLocation') || '/');
    sessionStorage.removeItem('previousLocation');
  }, []);

  if (isAuthenticated) {
    return <Navigate to={previousLocation} replace />;
  } else if (isNotAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  return <Loading />;
};

export default VerifyLogin;
