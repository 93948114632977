import React from 'react';
import Layout from '@/styles/Layout';
import { FlexColumn, FlexContainer, FlexItem } from '@/styles/Containers';
import { CustomizableHeading } from '@/styles/Text';
import { TextInput } from '@/components/navbar/Searchfield';
import { RedButton, TooltipButtonContainer } from '@/styles/Buttons';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import API_URL from '@/config';
import { TooltipText } from '@/components/RateCourseButton';
import { useAuth } from '@/utils/auth';

const Label = styled.label`
  font-weight: bold;
`;

const useDeleteUserMutation = () => {
  const { logOut } = useAuth();
  return useMutation({
    mutationFn: async (formData: FormData) => {
      await axios.delete(`${API_URL}/user/delete/?email=${formData.get('email')}`);
    },
    onSuccess: () => {
      logOut();
    },
  });
};

const MeDeletePage = () => {
  const [input, setInput] = React.useState('');
  const { mutate } = useDeleteUserMutation();
  const email = localStorage.getItem('email');
  return (
    <Layout padding='0 20%'>
      <FlexContainer margin='1rem 0 1rem 0'>
        <FlexItem>
          <CustomizableHeading headingLevel='h1' option='bold' margin='0 0 15px 0'>
            Slett brukeren din
          </CustomizableHeading>
          <div>
            Når du sletter brukeren din vil all din data bli slettet. Dette innebærer sletting av:
            <ul>
              <li>All informasjon vi har hentet fra Feide om deg (e-post, navn, studie)</li>
              <li>Alle vurderingen på emner du har skrevet</li>
              <li>Alle liker-klikk på vurderinger</li>
            </ul>
            Om du er helt sikker på at du vil slette brukeren din, skriv <b>{email}</b> inn i feltet under
          </div>
          <form
            method='post'
            onSubmit={(e) => {
              e.preventDefault();
              mutate(new FormData(e.currentTarget));
            }}
          >
            <FlexColumn gap='1rem' alignItems='start'>
              <Label htmlFor='email'>E-post</Label>
              <TextInput id={'email'} name={'email'} onChange={(e) => setInput(e.target.value)} />
              <TooltipButtonContainer>
                <RedButton disabled={input !== email} type={'submit'}>
                  Slett brukeren din
                </RedButton>
                {input !== email && <TooltipText>Du må oppgi e-postaddressen din</TooltipText>}
              </TooltipButtonContainer>
            </FlexColumn>
          </form>
        </FlexItem>
      </FlexContainer>
    </Layout>
  );
};

export default MeDeletePage;
