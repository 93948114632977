import React from 'react';

import styled from 'styled-components';
import Dropdown from 'react-dropdown';
import { ORDER_BY } from '@/types/query';

import 'react-dropdown/style.css';
import '@/utils/dropdown.css';

const DropdownContainer = styled.div`
  flex: 0 0 30%;
  @media (${({ theme }) => theme.device.tablet}) {
    flex: 0 0 70%;
  }
`;

// See src/types/query.d.ts
const options = Object.entries(ORDER_BY).map(([type, option]) => ({
  value: type,
  label: option.label,
}));

interface DropdownProps {
  setSortOption: React.Dispatch<React.SetStateAction<string>>;
  sortOption: string;
}

const DropdownCustom = ({ setSortOption, sortOption }: DropdownProps) => {
  return (
    <DropdownContainer defaultValue={sortOption}>
      <Dropdown
        value={sortOption}
        options={options}
        onChange={(e) => setSortOption(e.value)}
        className={'dropdown-header'}
        menuClassName={'dropdown-menu-header'}
        controlClassName={'dropdown-control-header'}
        placeholderClassName={'dropdown-text'}
        arrowClassName={'dropdown-arrow-header'}
      />
    </DropdownContainer>
  );
};

export default DropdownCustom;
