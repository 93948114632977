import React, { useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useOnClickOutside from '@/utils/useOnClickOutside';
import { HrLineMargin } from '../styles/Containers';
import Hamburger from './Hamburger';
import homeIcon from '../assets/icons/home.svg';
import loginIcon from '../assets/icons/login.svg';
import logOutIcon from '../assets/icons/logout.svg';
import meIcon from '../assets/icons/me.svg';
import infoIcon from '../assets/icons/info.svg';
import guidelinesIcon from '../assets/icons/rules.svg';
import useFeideUriHandler from '@/utils/useFeideUriHandler';
import { useAuth } from '@/utils/auth';

const StyledMenu = styled.nav<{ open: boolean }>`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  border-left-style: solid;
  border-color: lightgrey;
  border-width: 2px;
  padding-left: 2rem;

  transition: transform 0.4s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(60%)' : 'translateX(100%)')};

  @media ${({ theme }) => theme.device.laptop} {
    transform: ${({ open }) => (open ? 'translateX(56%)' : 'translateX(100%)')};
  }
  @media ${({ theme }) => theme.device.tabletLaptop} {
    transform: ${({ open }) => (open ? 'translateX(50%)' : 'translateX(100%)')};
  }
  @media ${({ theme }) => theme.device.tablet} {
    transform: ${({ open }) => (open ? 'translateX(45%)' : 'translateX(100%)')};
  }
  @media ${({ theme }) => theme.device.tabletMini} {
    transform: ${({ open }) => (open ? 'translateX(35%)' : 'translateX(100%)')};
    padding-left: 1rem;
  }
  @media ${({ theme }) => theme.device.mobileL} {
    transform: ${({ open }) => (open ? 'translateX(0%)' : 'translateX(100%)')};
  }
`;

const HrLineScaled = styled(HrLineMargin)`
  border-bottom: 1px solid ${({ theme }) => theme.color.darkBlue};
  width: calc(35% - 4rem);

  @media ${({ theme }) => theme.device.laptop} {
    width: calc(39% - 2rem);
    margin: 10px 0 0 10px;
  }

  @media ${({ theme }) => theme.device.tabletLaptop} {
    width: calc(45% - 2rem);
    margin: 10px 0 0 10px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: calc(50% - 4rem);
    margin: 10px 0 0 10px;
  }

  @media ${({ theme }) => theme.device.tabletMini} {
    width: calc(60% - 2rem);
    margin: 10px 0 0 10px;
  }

  @media ${({ theme }) => theme.device.mobileL} {
    width: calc(95% - 2rem);
    margin: 10px 0 0 10px;
  }
`;

const StyledLink = styled(Link)`
  margin: 1px;
  width: fit-content;
  font-size: 18px;
  font-family: poppinsregular;
  color: ${({ theme }) => theme.color.darkBlue};
  float: left;
  cursor: pointer;
  text-decoration: None;
  :hover {
    color: ${({ theme }) => theme.color.red};
  }
`;

const StyledExternalLink = styled.a`
  margin: 1px;
  width: fit-content;
  font-size: 18px;
  font-family: poppinsregular;
  color: ${({ theme }) => theme.color.darkBlue};
  float: left;
  cursor: pointer;
  text-decoration: None;
  :hover {
    color: ${({ theme }) => theme.color.red};
  }
`;
const LogInLinkDark = StyledExternalLink;

const LogInLinkLight = styled(StyledExternalLink)`
  color: ${({ theme }) => theme.color.white};
  :hover {
    color: ${({ theme }) => theme.color.lightBlue};
  }
  margin-right: 1em;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightFlexContainer = styled(FlexContainer)`
  position: absolute;
  z-index: 7;
  right: 1rem;
  top: 0.75rem;
  gap: 2rem;
  z-index: 7;
`;

const Icon = styled.img`
  padding: 10px;
  width: 25px;
`;

const Menu: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { handleFeideLogin } = useFeideUriHandler();
  const [open, setOpen] = useState<boolean>(false);
  const sideBarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(sideBarRef, () => setOpen(false));

  const handleClickLogOut = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  return (
    <div ref={sideBarRef}>
      <StyledMenu open={open}>
        <FlexContainer>
          <Icon src={homeIcon} alt='Home icon' />
          <StyledLink to='/'>Gå til forsiden</StyledLink>
        </FlexContainer>

        {isAuthenticated && (
          <FlexContainer>
            <Icon src={meIcon} alt='Me icon' />
            <StyledLink to='/me'>Gå til min side</StyledLink>
          </FlexContainer>
        )}

        <FlexContainer>
          <Icon src={guidelinesIcon} alt='Guidelines icon' />
          <StyledLink to='/guidelines'>Regler for bruk av siden</StyledLink>
        </FlexContainer>

        <FlexContainer>
          <Icon src={infoIcon} alt='Info icon' />
          <StyledLink to='/about'>Om EMNR</StyledLink>
        </FlexContainer>
        <HrLineScaled />
        {isAuthenticated ? (
          <FlexContainer>
            <Icon src={logOutIcon} alt='Log out icon' />
            <StyledLink to='/' onClick={handleClickLogOut}>
              Logg ut
            </StyledLink>
          </FlexContainer>
        ) : (
          <FlexContainer>
            <Icon src={loginIcon} alt='Log in icon' />
            <LogInLinkDark onClick={handleFeideLogin}>Logg inn</LogInLinkDark>
          </FlexContainer>
        )}
      </StyledMenu>

      <RightFlexContainer>
        {!isAuthenticated && <LogInLinkLight onClick={handleFeideLogin}>Logg inn</LogInLinkLight>}
        <Hamburger open={open} setOpen={setOpen} />
      </RightFlexContainer>
    </div>
  );
};
export default Menu;
