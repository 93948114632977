export type OrderByDirection = 'asc' | 'desc';

type ORDER_BY_TYPE = {
  [key in OrderByOption]: {
    label: string;
    result_metric: string;
    direction: OrderByDirection;
  };
};

export type OrderByOption = 'review_count' | 'course_code' | 'course_name_norwegian' | 'average_review_score' | 'average_grade' | 'pass_rate' | 'credit';

export const ORDER_BY: ORDER_BY_TYPE = {
  review_count: { label: 'Antall vurderinger', result_metric: 'Gjennomsnittlig vurdering', direction: 'desc' },
  course_code: { label: 'Emnekode', result_metric: 'Gjennomsnittlig vurdering', direction: 'asc' },
  course_name_norwegian: { label: 'Emnenavn', result_metric: 'Gjennomsnittlig vurdering', direction: 'asc' },
  average_review_score: { label: 'Vurdering', result_metric: 'Vurdering', direction: 'desc' },
  average_grade: { label: 'Karaktersnitt', result_metric: 'Karaktersnitt', direction: 'desc' },
  pass_rate: { label: 'Strykprosent', result_metric: 'Strykprosent', direction: 'desc' },
  credit: { label: 'Studiepoeng', result_metric: 'Studiepoeng', direction: 'desc' },
};

export const getOrderByLabel = (orderByOption: OrderByOption): string => {
  if (orderByOption in ORDER_BY) {
    return ORDER_BY[orderByOption].label;
  }
  return ORDER_BY['review_count'].label;
};

export const getOrderByResultMetric = (orderByOption: OrderByOption): string => {
  if (orderByOption in ORDER_BY) {
    return ORDER_BY[orderByOption].result_metric;
  }
  return ORDER_BY['review_count'].result_metric;
};

export const getOrderByDirection = (orderByOption: OrderByOption): OrderByDirection => {
  if (orderByOption in ORDER_BY) {
    return ORDER_BY[orderByOption].direction;
  }
  return ORDER_BY['review_count'].direction;
};
