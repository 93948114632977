import React from 'react';
import styled from 'styled-components';

const StyledHamburger = styled.button<{ open: boolean }>`
  width: 32px;
  height: 32px;
  background: transparent;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border: none;
  cursor: pointer;
  outline: none;
  z-index: 1;

  div {
    position: relative;
    width: 32px;
    height: 4px;
    border-radius: 10px;
    transition: all 0s linear;
    transform-origin: 1px;
    background-color: ${({ theme, open }) => (open ? theme.color.darkBlue : theme.color.white)};

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

interface HamburgerProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const Hamburger: React.FC<HamburgerProps> = ({ open, setOpen }) => {
  return (
    <StyledHamburger
      open={open}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div />
      <div />
      <div />
    </StyledHamburger>
  );
};
export default Hamburger;
