import React, { ReactNode } from 'react';
import { CourseItemText, CourseItemSubtext, ScoreSubText } from './CourseTexts';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StarIcon from '../../assets/icons/star.svg';
interface CourseProps {
  courseName: string;
  courseCode: string;
  averageReviewScore: number;
  reviewCount: number;
  averageGrade: number;
  passRate: number;
  credit: number;
  advancedSortingMatch: number;
  sortingParam: string;
}

interface CourseProps {
  padding?: string;
}

const CourseContainer = styled(Link)<{ children: ReactNode }>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.black};
  justify-content: space-between;
  border-radius: 10px;
  min-height: 150px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.lightlightGrey};
  :focus,
  :hover {
    cursor: pointer;
    scale: 1.01;
    outline: 1px solid ${({ theme }) => theme.color.blue};
  }
  @media ${({ theme }) => theme.device.mobileL} {
    flex-direction: row;
    border: 1px solid ${({ theme }) => theme.color.blue};
    color: ${({ theme }) => theme.color.black};
    border-radius: 20px;
    min-height: 70px;
    max-height: 70px;
    padding: 0.5rem;
  }
`;

const Star = styled.img`
  width: 18px;
  height: 18px;
`;

const Course: React.FC<CourseProps> = ({ courseName, courseCode, averageReviewScore, averageGrade, passRate, credit, advancedSortingMatch, sortingParam }) => {
  let paramMetric = '';
  let showStar: boolean;

  switch (sortingParam) {
    case 'course_code':
    case 'course_name':
    case 'average_review_score':
    case 'review_count':
      paramMetric = `${String(averageReviewScore.toFixed(1))} / 5`;
      showStar = true;
      break;
    case 'credit':
      paramMetric = String(credit);
      showStar = false;

      break;
    case 'average_grade':
      paramMetric = String(averageGrade.toFixed(1));
      showStar = false;

      break;
    case 'pass_rate':
      paramMetric = String((100 - passRate).toFixed(2));
      showStar = false;

      break;
    case 'advanced_sorting_score':
      paramMetric = `${String(advancedSortingMatch.toFixed(1))}%`;
      showStar = false;
      break;
    default:
      paramMetric = 'X';
      showStar = false;
      break;
  }

  return (
    <CourseContainer to={`/course/${courseCode}`}>
      <CourseItemSubtext>{courseCode}</CourseItemSubtext>
      <CourseItemText textAlign='center'>{courseName}</CourseItemText>
      <ScoreSubText>
        <span>{paramMetric}</span>
        {showStar ? <Star src={StarIcon} /> : <></>}
      </ScoreSubText>
    </CourseContainer>
  );
};

export default Course;
