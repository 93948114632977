import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import Layout from '@/styles/Layout';
import { FlexColumn, MobileFlexContainer, FlexItem, HrLine, ShapeContainer, FlexContainer } from '@/styles/Containers';
import { GoBackText, CustomizableHeading, StyledParagraph } from '@/styles/Text';
import { Circle, RotatedSquare } from '@/styles/Shapes';
import { defaultTheme } from '@/styles/theme';
import styled from 'styled-components';
import ReviewList from '@/components/ReviewList';
import ReviewForm from '@/components/ReviewForm';
import CourseInfoBox from '@/components/CourseInfoBox';
import Loading from '@/components/Loading';
import { Checkbox } from '@/styles/Buttons';
import { getLocalTokenUnverified } from '../utils/auth';
import redirectIcon from '../assets/icons/redirectIcon.svg';
import { useQuery } from '@tanstack/react-query';
import { CourseType } from '../models/course';
import { usePaginationContext } from '@/context/PaginationContext';
import NotFound from '@/components/NotFound';
import axios from 'axios';
import API_URL from '@/config';
import useSize from '@/utils/useSize';

const ExternalLinkContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const StyledExternalLink = styled.a`
  font-size: 16px;
  width: 100%;
  color: ${({ theme }) => theme.color.darkBlue};
  cursor: pointer;
  text-decoration: None;
  &:hover {
    text-decoration: underLine;
  }
`;

const Icon = styled.img`
  margin-left: 0.3em;
  width: 1.2em;
  vertical-align: middle;
`;
function generateCourseUrl(course: string) {
  return `https://www.ntnu.no/studier/emner/${course}`;
}

const useCourseQuery = (courseCode: string, onSuccess: (numberOfReviews: number) => void) =>
  useQuery<CourseType>(
    ['course', courseCode],
    async () => {
      const response = await axios.get(`${API_URL}/course/${courseCode}/`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        onSuccess(data.review_count ?? 0);
      },
    },
  );

const CoursePage = () => {
  const courseCode: string = decodeURIComponent(useLocation().pathname.substring(8));
  const [numberOfReviews, setNumberOfReviews] = useState<number>(0);
  const { isMobile } = useSize();

  const { data, isError, isLoading } = useCourseQuery(courseCode, setNumberOfReviews);

  const { setReviewPagination } = usePaginationContext();

  const navigate = useNavigate();
  const [reviewId, setReviewId] = useState<number>(0);
  const [edit, setEdit] = useState<boolean>(false);

  const [limitReviews, setLimitReviews] = useState<boolean>(localStorage.getItem('seeOnlyOwnProgrammeReviews') === 'true');

  Modal.setAppElement('#root');

  if (isError) {
    return <NotFound />;
  }

  return (
    <Layout padding='0 20%'>
      {!isMobile && (
        <ShapeContainer>
          <Circle color={defaultTheme.color.lightBlue} size='240px' right='0' top='130px' margin='0 -170px 0 0' />
          <RotatedSquare color={defaultTheme.color.blue} size='240px' left='0' top='50vh' angle='20deg' margin='0 0 0 -200px' mobileMargin='0 0 0 -400px' />
          <RotatedSquare color={defaultTheme.color.blue} size='240px' right='0' top='75vh' angle='80deg' margin='0 -225px 0 0' mobileMargin='0 -400px 0 0' />
        </ShapeContainer>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <FlexColumn alignItems='start' flex='0' width='100%' padding='0 1vw'>
          <FlexItem margin='1vh 0 3vh 0' onClick={() => navigate(-1)}>
            <GoBackText>Tilbake</GoBackText>
          </FlexItem>
          <MobileFlexContainer>
            <FlexItem margin='0 5vw 3vh 0'>
              <CustomizableHeading margin='0 0 5px' headingLevel='h2'>
                {courseCode}
              </CustomizableHeading>
              <CustomizableHeading margin='0 0 5px' option='bold' headingLevel='h1'>
                {data?.course_name_norwegian}
              </CustomizableHeading>
              <CustomizableHeading margin='0 0 5px' fontSize='50px' mobileFontSize='50px' option='bold' headingLevel='h3'>
                {data?.average_review_score.toFixed(1)} / 5
              </CustomizableHeading>
              <CustomizableHeading option='subtitle' headingLevel='h3' margin='0 0 4vh 0' fontWeight='400'>
                Basert på {numberOfReviews} {numberOfReviews === 1 ? 'vurdering' : 'vurderinger'}.
              </CustomizableHeading>
              <ReviewForm
                courseName={data?.course_name_norwegian ?? ''}
                courseCode={courseCode}
                reviewId={reviewId}
                edit={edit}
                setEdit={setEdit}
                setReviewId={setReviewId}
                reviewStatus={''}
              />
            </FlexItem>
            <FlexItem margin='0'>
              <CourseInfoBox
                difficulty={data?.average_difficulty ?? 0}
                workload={data?.average_workload ?? 0}
                averageGrade={data?.average_grade ?? 0}
                averageGradeLetter={data?.average_grade_letter}
                passRate={Number(data?.pass_rate.toFixed(0)) || 0}
                hasReview={numberOfReviews > 0}
              />
              <ExternalLinkContainer>
                <StyledExternalLink target='_blank' href={generateCourseUrl(courseCode)}>
                  Gå til NTNUs emneside
                  <Icon src={redirectIcon} alt='Redirect icon' />
                </StyledExternalLink>
              </ExternalLinkContainer>
            </FlexItem>
          </MobileFlexContainer>
        </FlexColumn>
      )}
      <HrLine />
      {(numberOfReviews > 0 || limitReviews) && getLocalTokenUnverified() && (
        <FlexContainer margin='0 0 0 5px' alignItems='center'>
          <CustomizableHeading margin='0' fontSize='0.9em' headingLevel='h3' fontWeight='400'>
            Mitt studieprogram:
          </CustomizableHeading>
          <Checkbox
            type='checkbox'
            checked={limitReviews}
            onChange={() => {
              setLimitReviews(!limitReviews);
              setReviewPagination((pagination) => ({ ...pagination, currentPage: 1 }));
              localStorage.setItem('seeOnlyOwnProgrammeReviews', String(!limitReviews));
            }}
          />
        </FlexContainer>
      )}

      {getLocalTokenUnverified() ? (
        <ReviewList courseCode={courseCode} limitReviews={limitReviews} numberOfReviewSetter={setNumberOfReviews} setReviewId={setReviewId} setEdit={setEdit} />
      ) : (
        <FlexContainer margin='3rem 0' alignItems='center' flexDirection='column'>
          <StyledParagraph>Du må være innlogget for å kunne lese og skrive reviews</StyledParagraph>
        </FlexContainer>
      )}
    </Layout>
  );
};

export default CoursePage;
