import { CenteredFlexContainer, ShapeContainer } from '@/styles/Containers';
import Layout from '@/styles/Layout';
import { Circle, RotatedSquare } from '@/styles/Shapes';
import { CustomizableHeading, StyledParagraph } from '@/styles/Text';
import { defaultTheme } from '@/styles/theme';
import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Layout padding='5% 20%'>
      <ShapeContainer>
        <Circle color={defaultTheme.color.lightBlue} size='240px' right='0' top='130px' margin='0 -170px 0 0' mobileMargin='0 -400px 0 0' />
        <RotatedSquare color={defaultTheme.color.blue} size='240px' left='0' top='50vh' angle='20deg' margin='0 0 0 -200px' mobileMargin='0 0 0 -400px' />
        <RotatedSquare color={defaultTheme.color.blue} size='240px' right='0' top='75vh' angle='80deg' margin='0 -225px 0 0' mobileMargin='0 -400px 0 0' />
      </ShapeContainer>
      <CenteredFlexContainer flexDirection='column'>
        <CustomizableHeading headingLevel='h1' mobileFontSize='28px' option='bold'>
          Personvernerklæring
        </CustomizableHeading>
        <StyledParagraph>
          Denne personvernerklæringen forklarer hvordan EMNR samler inn, behandler og bruker personopplysninger. Erklæringen inneholder opplysninger du har krav
          på når det samles inn opplysninger fra nettstedet vårt (personopplysningsloven § 19) og generell informasjon om hvordan vi behandler
          personopplysninger (personopplysningsloven § 18 første ledd).
        </StyledParagraph>
        <StyledParagraph>
          EMNR har ansvar for å følge{' '}
          <a href='https://lovdata.no/dokument/LTI/lov/2000-04-14-31' target='_blank' rel='noreferrer'>
            {' '}
            personopplysningsloven
          </a>
        </StyledParagraph>
        <CustomizableHeading mobileFontSize='28px' headingLevel='h2' option='bold'>
          Kontaktinfo
        </CustomizableHeading>
        <CustomizableHeading mobileFontSize='28px' headingLevel='h2' option='subtitle' margin='15px 0 0 0'>
          STUDENTMEDIENE I TRONDHEIM AS
        </CustomizableHeading>
        <ul>
          <li>
            <StyledParagraph style={{ margin: '0' }}>
              <b>Adresse:</b> Singsakerbakken 2E 7030 TRONDHEIM
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph style={{ margin: '0' }}>Telefon: +47 846387862</StyledParagraph>
          </li>
        </ul>
        <CustomizableHeading headingLevel='h3' margin='8px 0 0'>
          1. Behandlingsansvarlig
        </CustomizableHeading>
        <StyledParagraph>
          Daglig leder for Studentmediene i Trondheim AS er overordnet behandlingsansvarlig for EMNR. Vedkommende kan kontaktes på{' '}
          <a href='mailto: dagligleder@studentmediene.no'>dagligleder@studentmediene.no</a>. Prosjektleder for EMNR er delegert de daglige oppgavene for
          behandling av personopplysninger. Delegeringen omfatter kun oppgavene og ikke ansvaret. Prosjektleder kan kontaktes på e-postaddressen{' '}
          <a href='mailto: hjelp@emnr.no'>hjelp@emnr.no</a>.
        </StyledParagraph>
        <CustomizableHeading headingLevel='h3' margin='8px 0 0'>
          2. Personvernombud
        </CustomizableHeading>
        <StyledParagraph>
          EMNR har et personvernombud, gjennom eierorganisasjonen Studentmediene i Trondheim AS, for å sikre en trygg og god behandling av personopplysninger.
          Ordningen er initiert av Datatilsynet. Du kan kontakte Studentmediene sitt personvernombud på{' '}
          <a href='mailto:personvernombud@studentmediene.no'>personvernombud@studentmediene.no</a>
        </StyledParagraph>
        <CustomizableHeading headingLevel='h3' margin='8px 0 0'>
          3. Bruk av informasjonskapsler
        </CustomizableHeading>
        <StyledParagraph>Informasjonskapsler (cookies) er små tekstfiler som plasseres på din datamaskin når du laster ned en nettside.</StyledParagraph>
        <StyledParagraph>
          Når du logger inn med Feide på EMNR lagrer vi følgende informasjonskaplser i din nettleser: token og email. Disse blir brukt til å huske og
          identifisere deg som bruker, og at du skal forbli innlogget når du forlater siden. Disse blir slettet fra din nettleser når du logger ut. EMNR deler
          ikke disse informasjonskapslene med andre enn Feide.
        </StyledParagraph>
        <CustomizableHeading headingLevel='h3' margin='8px 0 0'>
          4. Databehandlere og tredjeparter
        </CustomizableHeading>
        <CustomizableHeading headingLevel='h4' fontSize='18px' margin='1em 0 0.5em 1em'>
          Feide
        </CustomizableHeading>
        <StyledParagraph>
          EMNR bruker Feide som leverandør av data om deg som student ved NTNU. Igjennom Feide kan EMNR hente data på vegne av deg når du er logget inn. Data om
          gruppetilhørligheter ved NTNU, altså hvilke emner du har tatt på NTNU, er hentet direkte fra Feide. Om denne dataen ikke er korrekt må det rettes opp
          hos NTNU. Hvilke data vi lagrer fra Feide står beskrevet i punkt (<b>5. Formålene EMNR bruker dine personopplysninger til</b>).
        </StyledParagraph>
        <CustomizableHeading headingLevel='h4' fontSize='18px' margin='1em 0 0.5em 1em'>
          Microsoft Azure
        </CustomizableHeading>
        <StyledParagraph>
          EMNR bruker Microsoft Azure som leverandør av infrastruktur, som servere, og andre tjenester. Microsoft Azure er dermed databehandler for EMNR.
          Microsoft har hovedkontor i USA, men EMNR benytter datasentrene &quot;West Europe&quot; og &quot;North Europe&quot; som er i henholdsvis Nederland og
          Irland. Alle serverne til EMNR kjøres i Azure. I tillegg lagres logger i Azure sin logganalyseløsning, og når du kontakter serverne våre går du via en
          av Azure sine lastbalanserere. For mer informasjon se:{' '}
          <a href='https://www.microsoft.com/nb-no/trust-center/privacy/gdpr-overview' target='_blank' rel='noreferrer'>
            {' '}
            Microsofts GDPR oversikt
          </a>
          .
        </StyledParagraph>
        <CustomizableHeading headingLevel='h4' fontSize='18px' margin='1em 0 0.5em 1em'>
          Sentry
        </CustomizableHeading>
        <StyledParagraph>
          EMNR bruker Sentry (Functional Software, Inc) som leverandør av feilvarslingsmottak, og er databehandler for EMNR. Sentry har hovedkontor i USA, men
          avtalen mellom EMNR og Sentry bruker standard personvernbestemmelser vedtatt av Europakommisjonen (Standard Contractual Clauses), som sikrer
          eventuelle personopplysninger som kommer i feilmeldinger. Som hovedregel fjerner vi personopplysninger før vi sender alarmer til Sentry, men i
          tilfellene hvor din enhet kontakter dem direkte, slik som ved feil som oppstår i nettleseren din mens du bruker EMNR, vil Sentry få blant annet
          brukernavn og IP- adressen din. Mer informasjon om hvordan Sentry behandler personopplysningene dine finner du her:{' '}
          <a href='https://sentry.io/legal/dpa' target='_blank' rel='noreferrer'>
            Databehandleravtalen
          </a>
        </StyledParagraph>
        <CustomizableHeading headingLevel='h3' margin='8px 0 0'>
          5. Personopplysninger
        </CustomizableHeading>
        <StyledParagraph>EMNR vil aldri selge brukerinformasjon til tredjepartsleverandører uten ditt samtykke.</StyledParagraph>
        <CustomizableHeading headingLevel='h4' fontSize='18px' margin='1em 0 0.5em 1em'>
          Formålene EMNR bruker dine personopplysninger til:
        </CustomizableHeading>
        <ul>
          <li>
            <StyledParagraph style={{ margin: '0' }}>
              EMNR lagrer feide brukernavn og fullt navn til brukeren. Dette er for at andre skal kunne se hvem som har skrevet vurderingen. Fullt navn lagres
              for at man skal kunne spore useriøse vurderinger og sørge for at brukerne følger retningslinjene til nettsiden.
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph style={{ margin: '0' }}>
              EMNR heter hvilke fag du har og har hatt ved NTNU fra Feide. EMNR lagrer bare hvilke fag du har fullført om du skriver en vurdering av emnet,
              dette lagres slik at brukere ikke skal kunne skrive flere vurderinger på et emne.
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph style={{ margin: '0' }}>
              {' '}
              Alle brukere har tilgang til å se hvem som har skrevet vurderinger basert på studieretning og fornavn.
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph style={{ margin: '0' }}>
              For å kunne slette inaktive brukere lagrer EMNR data om siste gangen en bruker logget inn.
            </StyledParagraph>
          </li>
        </ul>
        <CustomizableHeading headingLevel='h4' fontSize='18px' margin='1em 0 0.5em 1em'>
          Misbruk av tjenesten
        </CustomizableHeading>
        <StyledParagraph>
          Brukere må følge EMNR sine regler for emnevurderinger. Dersom en vurdering bryter reglene vil den bli slettet. Ved gjentagende brudd vil brukeren bli
          utestengt fra EMNR. Dette betyr at brukeren ikke kan interagere med de ulike emnene. Når en brukerkonto blir utestengt vil vi varsle innehaveren av
          kontoen på oppgitt epostadresse. Les mer om dette på <Link to='/guidelines'>Retningslinjer</Link>
        </StyledParagraph>
        <CustomizableHeading headingLevel='h3' margin='8px 0 0'>
          6. Sletting av personopplysninger
        </CustomizableHeading>
        <StyledParagraph>
          Brukere kan selv slette sine brukerdata på nettsiden. Om du ønsker å slette din brukerkonto og alle dine brukergenerete data som vurderinger, kan du
          selv gjøre dette på &ldquo;Min side&rdquo;. Om du ønsker å slette din brukerkonto, men ikke kan logge inn, ta kontakt med oss på{' '}
          <a href='mailto: hjelp@emnr.no'>hjelp@emnr.no</a>
        </StyledParagraph>
        <CustomizableHeading headingLevel='h4' fontSize='18px' margin='1em 0 0.5em 1em'>
          Hvor lenge lagres brukerdataen om meg?
        </CustomizableHeading>
        <StyledParagraph>
          Din data kan når som helst slettes av deg selv. Om brukeren din er inaktiv vil din brukerkonto bli slettet og tilhørende data bli anonymisert etter 3
          år
        </StyledParagraph>
        <CustomizableHeading headingLevel='h3' margin='8px 0 0'>
          7. Dine rettigheter
        </CustomizableHeading>
        <StyledParagraph>
          Alle som spør har rett på grunnleggende informasjon om behandling av personopplysninger i en virksomhet etter personopplysningsloven § 18 første ledd.
          EMNR har gitt denne informasjonen i denne erklæringen og vil henvise til den ved eventuelle forespørsler. Dersom du er registrert i et av EMNRs
          systemer har du rett på innsyn i egne opplysninger. Du har også rett til å be om at uriktige, ufullstendige eller opplysninger EMNR ikke har adgang
          til å behandle blir rettet, slettet eller supplert. Krav fra den registrerte skal besvares kostnadsfritt og senest innen 30 dager.
        </StyledParagraph>
        <StyledParagraph>
          Gjennom{' '}
          <a href='https://innsyn.feide.no' target='_blank' rel='noreferrer'>
            Feides innsynstjeneste
          </a>{' '}
          har du oversikt over hvilke opplysninger EMNR har tilgang til på vegne av deg. Du har samtykket til dette ved første innlogging. Du har rett til å
          trekke tilbake samtykket og dette gjøres gjennom{' '}
          <a href='https://innsyn.feide.no/applications' target='_blank' rel='noreferrer'>
            Feides applikasjonsoversikt
          </a>
          . Om du velger å trekke tilbake samtykket vil ikke fremtidige opplysninger være tilgjengelig.
        </StyledParagraph>
      </CenteredFlexContainer>
    </Layout>
  );
};

export default PrivacyPolicyPage;
