import React, { useState } from 'react';
import styled from 'styled-components';
import ReportFilled from '@/assets/icons/reportFilled.svg';
import ReportOutlined from '@/assets/icons/reportOutlined.svg';
import { FlexContainer } from '@/styles/Containers';
import axios from 'axios';
import API_URL from '@/config';
import { CustomizableHeading } from '@/styles/Text';
import { TooltipButtonContainer, ModalXButton, BlueButton } from '@/styles/Buttons';
import { TooltipText } from '@/components/RateCourseButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Modal from 'react-modal';
import { modalStyles, GreyHrLine } from '@/styles/Modals';

const ReportButton = styled.img`
  width: 30px;
  float: left;
  margin-right: 10px;
  cursor: pointer;
`;

const ClickedReport = styled(ReportButton)`
  filter: invert(48%) sepia(96%) saturate(3381%) hue-rotate(328deg) brightness(86%) contrast(112%);
  margin-right: 10px;
  cursor: auto;
  animation: like 300ms ease-out;
  @keyframes like {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.4);
      height: 28px;
    }
    100% {
      transform: scale(1);
    }
  }
`;

const TextInput = styled.textarea`
  border: 1px solid rgba(69, 123, 157, 0.62);
  border-radius: 10px;
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  margin: 5px 0 5px;
  padding: 0.5rem;
`;

const ItalicText = styled.p`
  font-style: italic;
  font-size: 14px;
`;

interface ReportProps {
  reviewId: number;
  reportStatus: number;
}

interface Report {
  report_reason: string;
}

const usePostReport = (onSuccess: (reviewId: number) => void, reviewId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['postReport'],
    async (report: Report) => {
      await axios.post(`${API_URL}/report/?reviewId=${reviewId}`, report);
    },
    {
      onSuccess: () => {
        onSuccess(1);
        queryClient.invalidateQueries(['review', reviewId]);
      },
    },
  );
};

const ReportReview = ({ reviewId, reportStatus }: ReportProps) => {
  const [status, setStatus] = useState<number>(reportStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportText, setReportText] = useState('');
  const { mutate } = usePostReport(setStatus, reviewId);

  const report = { report_reason: reportText };

  let content;

  switch (status) {
    // Status 0: Can report
    // Status 1: Reported
    // Status 2: User cannot report because its expiring token does not exist.
    // Status 3: User is banned from making reports
    case 1:
      content = (
        <>
          <ClickedReport src={ReportFilled} />
          <TooltipText>Du har rapportert anmeldelsen.</TooltipText>
        </>
      );
      break;
    case 2:
      content = (
        <>
          <ClickedReport src={ReportFilled} />
          <TooltipText>Din token har utløpt.</TooltipText>
        </>
      );
      break;
    case 3:
      content = (
        <>
          <ClickedReport src={ReportFilled} />
          <TooltipText>Du har blitt utestengt.</TooltipText>
        </>
      );
      break;
    default:
      content = (
        <>
          <ReportButton src={ReportOutlined} onClick={() => setIsModalOpen(true)} />
          <TooltipText>Rapportér anmeldelse</TooltipText>
        </>
      );
      break;
  }

  return (
    <>
      <TooltipButtonContainer>{content}</TooltipButtonContainer>
      {isModalOpen && (
        <>
          <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={modalStyles} contentLabel='Report form modal'>
            <FlexContainer justifyContent='space-between'>
              <CustomizableHeading headingLevel='h1' option='bold' fontSize='22px'>
                Rapportér vurdering
              </CustomizableHeading>
              <ModalXButton onClick={() => setIsModalOpen(false)}>&#10006;</ModalXButton>
            </FlexContainer>
            <GreyHrLine />
            <ItalicText>
              Her kan du rapportere vurderingen som upassende. <br /> Merk! Du kan ikke slette rapporten selv. Ta kontakt med{' '}
              <a href='mailTo:hjelp@emnr.no'>hjelp@emnr.no</a> om du har feilrapportert en vurdering.
            </ItalicText>
            <label>
              Begrunnelse for rapportering
              <TextInput value={reportText} maxLength={160} onChange={(e) => setReportText(e.target.value)} />
            </label>
            <BlueButton
              onClick={() => {
                mutate(report);
                setIsModalOpen(false);
              }}
            >
              Send inn
            </BlueButton>
          </Modal>
        </>
      )}
    </>
  );
};

export default ReportReview;
