import { createGlobalStyle } from 'styled-components';

import PoppinsLight from '../fonts/poppins-light-300.woff';
import PoppinsRegular from '../fonts/poppins-light-300.woff';
import PoppinsMedium from '../fonts/poppins-medium-500.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'poppinslight';
    font-style: normal;
    font-weight: normal;
    src: url(${PoppinsLight});
  }
  @font-face {
    font-family: 'poppinsregular';
    font-style: normal;
    font-weight: normal;
    src: url(${PoppinsRegular});
  }
  @font-face {
    font-family: 'poppinsmedium';
    font-style: normal;
    font-weight: normal;
    src: url(${PoppinsMedium});
  }
  // latin characters
  @font-face {
    font-family: 'poppinsmedium';
    font-style: normal;
    font-weight: normal;
    src: url("https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V15vFP-KUEg.woff2") format('woff2');
  }

  body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    background: ${({ theme }) => theme.color.white};
    font-family: 'poppinslight', Open-Sans, Helvetica, Sans-Serif;
  }
`;

export default GlobalStyle;
