import { useEffect, useRef } from 'react';

export default function useInterval(callback: () => void, delay: number, clearOnNext = false) {
  const intervalId = useRef(0);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay && typeof delay === 'number') {
      intervalId.current = window.setInterval(tick, delay);
    }
    if (clearOnNext) {
      window.clearInterval(intervalId.current);
    }
    return () => window.clearInterval(intervalId.current);
  }, [delay, clearOnNext]);
  return intervalId.current;
}
