import React from 'react';
import styled from 'styled-components';

interface PaginationProps {
  backgroundColor?: string;
  color?: string;
}

interface PaginationInterface {
  active?: boolean;
  onClick: () => void;
}

const PaginationBtn = styled.button`
  background-color: ${(props: PaginationProps) => props.backgroundColor};
  color: ${(props: PaginationProps) => props.color};
  font-family: poppinsmedium;
  border: none;
  padding: 10px;
  min-width: 40px;
  cursor: pointer;
  margin: 0;
  border-radius: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.device.tablet} {
    &:hover {
      background-color: ${({ theme }) => theme.color.blue};
      color: ${({ theme }) => theme.color.white};
    }
  }
  &:focus-visible {
    outline: 2px solid #5e9ed6;
  }
  &.active {
    color: #fff;
  }
`;

const PaginationButton: React.FC<PaginationInterface> = ({ children, active, onClick }) => {
  return (
    <PaginationBtn onClick={onClick} backgroundColor={active ? '#457B9D' : '#E8E8E8'} color={active ? '#fff' : '#000'}>
      {children}
    </PaginationBtn>
  );
};

export default PaginationButton;
