import { CourseList } from '@/components/course/CourseList';
import React from 'react';
import { Pagination } from '@/components/pagination/Pagination';
import Layout from '@/styles/Layout';

const SearchPage = () => {
  return (
    <Layout>
      <CourseList />
      <Pagination currentPage='courses' />
    </Layout>
  );
};

export default SearchPage;
