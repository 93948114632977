import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    device: {
      mobileS: string;
      mobileM: string;
      mobileL: string;
      tabletMini: string;
      tablet: string;
      tabletLaptop: string;
      laptop: string;
      laptopL: string;
      desktop: string;
    };
    color: {
      red: string;
      light: string;
      lightlightBlue: string;
      lightBlue: string;
      blue: string;
      darkBlue: string;
      white: string;
      black: string;
      lightlightGrey: string;
      lightGrey: string;
      grey: string;
    };
  }
}

export const defaultTheme: DefaultTheme = {
  // Standardized breakpoints
  device: {
    mobileS: '(max-width: 320px)',
    mobileM: '(max-width: 375px)',
    mobileL: '(max-width: 440px)',
    tabletMini: '(max-width: 600px)',
    tablet: '(max-width: 768px)',
    tabletLaptop: '(max-width: 830px)',
    laptop: '(max-width: 1024px)',
    laptopL: '(max-width: 1440px)',
    desktop: '(max-width: 2560px)',
  },
  color: {
    red: '#E63946',
    lightlightBlue: '#EDF6F9',
    light: '#F1FAEE',
    lightBlue: '#A8DADC',
    blue: '#457B9D',
    darkBlue: '#1E3859',
    white: '#FFF',
    black: '#000',
    lightlightGrey: '#F6F6F6',
    lightGrey: '#E6E6E6',
    grey: '#EFEFEF',
  },
};
