import React from 'react';
import styled from 'styled-components';

interface SliderProps {
  percentage: number;
}

const SliderContainer = styled.div`
  margin: 5px 0 15px 0;
  height: 11px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.darkBlue};
  display: flex;
  align-items: center;
  border: 1px solid #758ca3;
`;

const Filler = styled.div<{ percentage: number }>`
  border-radius: ${({ percentage }) => `${percentage === 100 ? '10px' : '10px 0 0 10px'}}`};
  text-align: right;
  background-color: ${({ theme }) => theme.color.red};
  width: ${({ percentage }) => `${percentage}%`};
  height: 100%;
  margin: 0;
  border-right: ${({ percentage }) => `${percentage === 100 ? '10px' : '1px solid #758ca3'}}`};
  transition: width 1s ease-in-out;
`;

const ReviewedSlider = ({ percentage }: SliderProps) => {
  return (
    <SliderContainer>
      <Filler percentage={percentage} />
    </SliderContainer>
  );
};

export default ReviewedSlider;
