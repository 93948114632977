import API_URL from '@/config';
import { BlueButton, RedButton } from '@/styles/Buttons';
import { FlexColumn } from '@/styles/Containers';
import { modalStyles } from '@/styles/Modals';
import { CustomizableHeading, StyledParagraph } from '@/styles/Text';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type PrivacyPopUpProps = {
  isOpen: boolean;
  handleCloseModal: (value: boolean) => void;
};

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  position: sticky;
  margin-top: 1rem;
  @media (max-width: 320px) {
    margin: 0;
  }
`;

const CustomFlexColumn = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 30vh;
  overflow: auto;
  background-color: ${({ theme }) => theme.color.lightlightBlue};
  border-radius: 10px;
  padding: 5px 30px 0 30px;
`;

const customModalStyles = {
  ...modalStyles,
  content: {
    ...modalStyles.content,
    overflow: 'hidden',
  },
};

const Title = styled(CustomizableHeading)`
  @media (max-width: 320px) {
    text-align: left;
    margin: auto;
  }
`;

const PrivacyPopUp = ({ isOpen, handleCloseModal }: PrivacyPopUpProps) => {
  const mutation = useMutation(
    async () => {
      const response = await axios.post(`${API_URL}/user/read_privacy_policy/`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data === 'User has now read the privacy policy') {
          handleCloseModal(false);
        }
      },
    },
  );

  const toggleModalIsOpen = () => {
    handleCloseModal(false);
    mutation.mutate();
  };

  const toggleRejectCloseModal = () => {
    handleCloseModal(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      resetOverflow();
    }
  }, [isOpen]);

  const resetOverflow = () => {
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      <Modal
        style={customModalStyles}
        isOpen={isOpen}
        onRequestClose={toggleModalIsOpen}
        shouldCloseOnOverlayClick={false}
        appElement={document.getElementById('root') as HTMLElement} // to not get "App element not defined"
      >
        <Title headingLevel='h1' textAlign={'center'}>
          Personvernerklæring
        </Title>
        <CustomFlexColumn>
          <StyledParagraph>
            Denne personvernerklæringen forklarer hvordan EMNR samler inn, behandler og bruker personopplysninger. Erklæringen inneholder opplysninger du har
            krav på når det samles inn opplysninger fra nettstedet vårt (personopplysningsloven § 19) og generell informasjon om hvordan vi behandler
            personopplysninger (personopplysningsloven § 18 første ledd). EMNR har ansvar for å følge, {''}
            <a href={'https://lovdata.no/dokument/LTI/lov/2000-04-14-31'} target='_blank' rel='noreferrer'>
              Personopplysningsloven
            </a>
          </StyledParagraph>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', width: '100%' }}>
            <CustomizableHeading headingLevel='h6'>Kontaktinfo:</CustomizableHeading>
            <ul>
              <li>Adresse: Singsakerbakken 2E 7030 TRONDHEIM</li>
              <li>Telefon: +47 846387862</li>
            </ul>
          </div>

          <ul>
            <li>
              Datainnsamling: EMNR samler inn data om deg, inkludert fag du har tatt, fra Feide. Dette gjøres ved at vi lagrer informasjonskapsler (cookies) i
              nettleseren. Disse inneholder emailen din og en token, og blir brukt til å huske og identifisere deg som bruker.
            </li>
            <li>Databehandlere: EMNR bruker Feide, Microsoft Azure og Sentry som databehandlere.</li>
            <li>Tredjeparter: EMNR vil aldri selge brukerinformasjon til tredjeparter uten ditt samtykke.</li>
          </ul>
        </CustomFlexColumn>
        <Footer>
          <Link to='/privacy'>Se hele personvernerklæringen</Link>
          <BlueButton onClick={toggleModalIsOpen}>Jeg har lest personvernerklæringen</BlueButton>
          <RedButton onClick={toggleRejectCloseModal}>Jeg aksepterer ikke personvernerklæringen</RedButton>
        </Footer>
      </Modal>
    </>
  );
};

export default PrivacyPopUp;
