import React from 'react';
import Layout from '@/styles/Layout';
import MyCoursesList from '@/components/mypage/MyCoursesList';
import { FlexContainer, FlexItem } from '@/styles/Containers';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CustomizableHeading, RedHighlight } from '@/styles/Text';
import { useQuery } from '@tanstack/react-query';
import { getLocalEmail } from '@/utils/auth';
import axios from 'axios';
import API_URL from '@/config';

const DeleteUserText = styled.p`
  color: red;
  font-size: 1.25rem;
  margin-bottom: 2rem;
`;

const fetchCurrentUser = async () => {
  return await (
    await axios.get(`${API_URL}/user/me`)
  ).data;
};

export type CurrentUser = {
  email: string;
  first_name: string;
  last_name: string;
};

const useCurrentUserQuery = () => useQuery<{ user: CurrentUser }>(['user', 'me', getLocalEmail()], fetchCurrentUser);

const MePage = () => {
  const { data, isLoading } = useCurrentUserQuery();
  return (
    <Layout padding='0 20%'>
      <FlexContainer margin='15px 0 0 0'>
        <FlexItem>
          <CustomizableHeading margin='0 0 15px 0' headingLevel='h1' option='bold'>
            Velkommen til din side
            {!isLoading && (
              <>
                , <RedHighlight>{data?.user.first_name}</RedHighlight>
              </>
            )}
            !
          </CustomizableHeading>
          <MyCoursesList />
          <DeleteUserText>
            Ønsker du å slette brukeren din? <Link to='/me/delete'>Trykk her</Link>
          </DeleteUserText>
        </FlexItem>
      </FlexContainer>
    </Layout>
  );
};

export default MePage;
