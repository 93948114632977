import styled from 'styled-components';

interface LayoutProps {
  margin?: string;
  padding?: string;
  flex?: string;
  width?: string;
  textAlign?: string;
  flexWrap?: string;
  flexDirection?: 'row' | 'column';
  justifySelf?: string;
  justifyContent?: string;
  gap?: string;
  alignItems?: string;
}

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: LayoutProps) => props.flexDirection || 'row'};
  flex-wrap: ${(props: LayoutProps) => props.flexWrap || 'nowrap'};
  margin: ${(props: LayoutProps) => props.margin || 'auto'};
  width: ${(props: LayoutProps) => props.width || ''};
  justify-self: ${(props: LayoutProps) => props.justifySelf || ''};
  padding: ${(props: LayoutProps) => props.padding || ''};
  justify-content: ${(props: LayoutProps) => props.justifyContent || ''};
  align-items: ${(props: LayoutProps) => props.alignItems || ''};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: LayoutProps) => props.width || ''};
  padding: ${(props: LayoutProps) => props.padding || ''};
  gap: ${(props: LayoutProps) => props.gap || ''};
  box-sizing: border-box;
  height: 100%;
  align-items: ${(props: LayoutProps) => props.alignItems || 'center'};
`;

export const SemesterContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.lightlightBlue};
  flex-direction: column;
  margin: ${(props: LayoutProps) => props.margin || 'auto'};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.darkBlue};
  padding: 16px 16px 8px 16px;
  @media ${({ theme }) => theme.device.mobileL} {
    padding: 5px;
  }
`;

export const SemesterCoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-top: 0.4rem;
  @media ${({ theme }) => theme.device.mobileL} {
    gap: 0.5rem;
  }
`;

export const MobileFlexContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap; // As a backup to the @media solution

  @media ${({ theme }) => theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const CoursesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  min-height: 200px;
  margin-top: 1em;
  padding: ${(props: LayoutProps) => props.padding || 'auto'};
  width: ${(props: LayoutProps) => props.width || ''};
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
  @media ${({ theme }) => theme.device.mobileL} {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
`;

export const CourseContainer = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: white;
  justify-content: space-between;
  border-radius: 10px;
  min-height: 150px;
  padding: ${(props: LayoutProps) => props.padding || '1em'};
  background-color: ${({ theme }) => theme.color.blue};
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  :hover {
    background-color: ${({ theme }) => theme.color.lightBlue};
    color: ${({ theme }) => theme.color.darkBlue};
    color: black;
    border: 1px solid black;
  }
  @media ${({ theme }) => theme.device.mobileM} {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
`;

export const CenteredFlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: LayoutProps) => props.flexDirection || 'row'};
  flex-wrap: ${(props: LayoutProps) => props.flexWrap || 'nowrap'};
  justify-content: center;
  margin: ${(props: LayoutProps) => props.margin || 'auto'};
  gap: ${(props: LayoutProps) => props.gap || '0'};
`;

// Used to hide shape overflow
export const ShapeContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const LocalShapeContainer = styled.div`
  position: relative;
  z-index: -1;
  overflow: hidden;
  flex: 1;
`;

export const FlexItem = styled.div`
  flex: ${(props: LayoutProps) => props.flex || '1'};
  margin: ${(props: LayoutProps) => props.margin || 'auto'};
  width: '';
`;

export const HrLine = styled.div`
  margin: ${(props: LayoutProps) => props.margin || '3vh 0 2vh 0'};
  border-bottom: 1px solid ${({ theme }) => theme.color.darkBlue};
`;

export const HrLineMargin = styled(HrLine)`
  border-bottom: 2px solid ${({ theme }) => theme.color.darkBlue};
  width: 23%;
  margin-left: 1%;
`;

export const SemesterRowHeader = styled.div`
  width: 100%;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 1rem;
  align-items: center;

  border-radius: 10px;

  margin-bottom: 0;

  padding: 0 1.5rem;
  color: #1d3557;
  font-family: poppinsmedium;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 2fr 4fr 2fr;
    padding: 0 0.5em;
    gap: 0.25rem;
  }
`;

export const UnavailableSemesterRow = styled(SemesterRowHeader)`
  background-color: #e0e0e0;
  border: 1px solid ${({ theme }) => theme.color.black};
`;

export const SemesterRow = styled(UnavailableSemesterRow)<{ status: string }>`
  background-color: ${({ theme, status }) => {
    if (status === 'published') return '#E4FFC1';
    else if (status === 'draft') return '#F5F3B5';
    else return theme.color.white;
  }};
  transition: scale 0.2s ease-in-out, outline 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    cursor: pointer;
    scale: 1.01;
    outline: 1px solid ${({ theme }) => theme.color.blue};
  }
`;

export const StyledTH = styled.th`
  padding: 0 1rem;
  color: #1d3557;
  font-family: poppinsmedium;
  width: ${(props: LayoutProps) => props.width || 'auto'};
  text-align: ${(props: LayoutProps) => props.textAlign || 'auto'};
  margin: ${(props: LayoutProps) => props.margin || 'auto'};
`;

export const CarouselTextContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 25%;
`;

export const CarouselCardTitle = styled.p`
  font-family: poppinsmedium;
  text-align: center;
  font-weight: bold;
  font-size: 1.05rem;
  height: 10%;
`;

export const CarouselCardText = styled.p`
  font-family: poppinsmedium;
  text-align: center;
  font-size: 0.9rem;
`;
