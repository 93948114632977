import React from 'react';
import axios from 'axios';
import { CustomizableHeading, RedHighlight } from '@/styles/Text';
import { MyCourse, Semester } from './Semester';
import API_URL from '../../config';
import Loading from '../Loading';
import { EmptyResult } from '../course/CourseList';
import ReviewedSlider from './ReviewedSlider';
import { useQuery } from '@tanstack/react-query';

interface SemestersDict {
  [semester: string]: MyCourse[];
}

export type Semesters = [string, MyCourse[]][];

// TODO: Put this logic in the backend, it is easier to do it there
const sortSemesters = (semester1: [string, MyCourse[]], semester2: [string, MyCourse[]]) => {
  const [semester1Season, semester1Year] = semester1[0].split(' ');
  const semester2Year = semester2[0].split(' ')[1];

  if (parseInt(semester1Year, 10) < parseInt(semester2Year, 10)) {
    return 1;
  }
  if (parseInt(semester1Year, 10) === parseInt(semester2Year, 10)) {
    if (semester1Season === 'Vår') {
      return 1;
    }
    return -1;
  }
  return -1;
};

// TODO: Put this logic in the backend
const getSemestersAndReviewAmountFromMyCourses = (myCourses: MyCourse[]) => {
  let reviewCount = 0;
  const sortedCourses = myCourses.reduce((semesters, course) => {
    const sortedSemesters: SemestersDict = { ...semesters };
    if (!sortedSemesters[course.semester]) sortedSemesters[course.semester] = [];
    sortedSemesters[course.semester].push(course);
    // Count percentage of reviewed courses
    if (course.review_status === 'published') {
      reviewCount++;
    }
    return sortedSemesters;
  }, {} as SemestersDict);
  const semesters = Object.entries(sortedCourses).sort(sortSemesters);
  const reviewAmount = parseInt(((reviewCount / myCourses.length) * 100).toFixed(0), 10);
  return { semesters, reviewAmount };
};

const useMyCoursesQuery = () => {
  return useQuery(['myCourses'], async () => {
    const response = await axios.get(`${API_URL}/course/me/`);
    const allMyCourses: MyCourse[] = response.data;
    return getSemestersAndReviewAmountFromMyCourses(allMyCourses);
  });
};

const MyCoursesList = () => {
  const { data, isLoading, isError, error, refetch } = useMyCoursesQuery();
  const { semesters, reviewAmount } = data || { semesters: [], reviewAmount: 0 };

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <EmptyResult>Det oppstod en feil: {(error as Error).message}</EmptyResult>;
  }
  if (semesters.length === 0) {
    return <EmptyResult>Beklager! Vi fant ingen data. </EmptyResult>;
  }
  return (
    <>
      <CustomizableHeading headingLevel='h2' margin='0 0 15px' option='subtitle' fontWeight='400'>
        Du har vurdert <RedHighlight>{reviewAmount}%</RedHighlight> av emnene dine.
      </CustomizableHeading>
      <ReviewedSlider percentage={reviewAmount} />
      {semesters.map(([semester, courses]) => {
        return <Semester semester={semester} courses={courses} key={semester} refetch={refetch} />;
      })}
    </>
  );
};

export default MyCoursesList;
