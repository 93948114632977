import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';
import API_URL from '../config';

export type TokenResponse = {
  email: string;
  token: string;
};

type ValidationResponse = {
  data: string;
};

type FeideLoginResponse = {
  token: string;
  email: string;
};

export const useGetFeideLoginQuery = () =>
  useQuery(['auth', 'logInURL'], async () => {
    return axios.get(`${API_URL}/auth/get_login/`).then((response) => {
      return response.data;
    });
  });

export const useVerifyFeideLoginCodeQuery = (code: string, isAuthenticated: boolean) =>
  useQuery(
    ['auth', 'code', code],
    async () => {
      return axios.get(`${API_URL}/auth/verify_login/`, { params: { code } }).then((response) => {
        return response.data;
      });
    },
    {
      enabled: !isAuthenticated,
      onError: () => console.error('login failed'),
      onSuccess: (data: FeideLoginResponse) => {
        localStorage.setItem('token', `Token ${data.token}`);
        localStorage.setItem('email', data.email);
        axios.defaults.headers.common.Authorization = `Token ${data.token}`;
      },
    },
  );

export const useValidateTokenQuery = (email: string | null, token: string | null) =>
  useQuery<ValidationResponse>(
    ['validate', email, 'valid'],
    async () => {
      axios.defaults.headers.common.Authorization = `${token}`;
      return await axios.get(`${API_URL}/auth/validate_token/`, {
        params: {
          email,
        },
      });
    },
    {
      onSuccess: (response) => {
        if (response.data === 'True') {
          return true;
        }

        logOut();
      },
      refetchInterval: 10 * 60 * 1000,
      enabled: email !== null && token !== null,
    },
  );

export const getLocalEmail = () => {
  if (localStorage.getItem('email')) {
    return localStorage.getItem('email');
  }
  return null;
};

export const getLocalTokenUnverified = () => {
  return localStorage.getItem('token');
};

export const logOut = () => {
  localStorage.clear();
  window.history.pushState({}, '', '/login');
  window.location.reload();
};

export const useAuth = () => {
  const { data: feideLogInURL } = useGetFeideLoginQuery();
  const logInWithRedirect = useCallback(() => {
    window.location.href = feideLogInURL;
  }, [feideLogInURL]);
  const { data: isAuthenticated } = useValidateTokenQuery(getLocalEmail(), getLocalTokenUnverified());
  return {
    isAuthenticated: isAuthenticated?.data === 'True',
    isNotAuthenticated: isAuthenticated?.data === 'False',
    email: isAuthenticated ? getLocalEmail() : null,
    logOut,
    logInWithRedirect,
    feideLogInURL,
  };
};
