import React from 'react';
import { CenteredFlexContainer, ShapeContainer } from '@/styles/Containers';
import Layout from '@/styles/Layout';
import { CustomizableHeading, StyledParagraph } from '@/styles/Text';
import { Circle, RotatedSquare } from '@/styles/Shapes';
import { defaultTheme } from '@/styles/theme';

const GuidelinesPage: React.FC = () => {
  return (
    <Layout padding='0 20%'>
      <ShapeContainer>
        <Circle color={defaultTheme.color.lightBlue} size='240px' right='0' top='130px' margin='0 -170px 0 0' mobileMargin='0 -400px 0 0' />
        <RotatedSquare color={defaultTheme.color.blue} size='240px' left='0' top='50vh' angle='20deg' margin='0 0 0 -200px' mobileMargin='0 0 0 -400px' />
        <RotatedSquare color={defaultTheme.color.blue} size='240px' right='0' top='75vh' angle='80deg' margin='0 -225px 0 0' mobileMargin='0 -400px 0 0' />
      </ShapeContainer>
      <CenteredFlexContainer flexDirection='column' margin='5% 10%'>
        <CustomizableHeading headingLevel='h1' mobileFontSize='28px' option='bold'>
          Regler for emnevurderinger
        </CustomizableHeading>
        <br />
        <StyledParagraph>
          <ol>
            <li>
              <b>Emnevurderinger skal være saklige.</b> Vurderingene på siden skal omhandle emnet vurderingen er skrevet for. Vurderinger som tydelig er
              usaklige blir slettet.
            </li>
            <br />
            <li>
              <b>Personangrep eller &#34;trolling&#34; er ikke tillatt.</b> Fokuser på emnet, ikke enkeltpersoner i fagstaben. Det gjelder også skjellsord og
              særlig ukonstruktive eller nedsettende kommentarer. &#34;Trolling&#34; vil si å unødig provosere folk.
            </li>
            <br />
            <li>
              <b>Ikke spam.</b> Det vil blant annet si off-topic fra emnet, eller unødvendige usakligheter.
            </li>
            <br />
            <li>
              <b>Sletting fra moderatorer skal følges.</b> Det er for øyeblikket utviklingsteamet som gjør modereringen. Blir en vurdering du har skrevet
              slettet, skal du ikke skrive den samme på nytt. Lurer du på hvorfor vurderingen din ble slettet, kan du ta kontakt med oss på{' '}
              <a href='mailTo:hjelp@emnr.no'>hjelp@emnr.no</a>
            </li>
            <br />
            <li>
              <b>Uenighet med moderering skal tas opp på mail.</b> Er man uenig med en moderering, skal man ta det opp med oss på EMNR sin epost:{' '}
              <a href='mailTo:hjelp@emnr.no'>hjelp@emnr.no</a> Det samme gjelder klager på andres regelbrudd.
            </li>
            <br />
            <li>
              <b>Selvpromotering og spørreundersøkelser.</b> Reklamering for andre grupper/sider, nettsider, events, blogger, spørreundersøkelser og
              selvpromotering er i utgangspunktet ikke lov. Unntak kan søkes om ved å sende en epost til <a href='mailTo:hjelp@emnr.no'>hjelp@emnr.no</a>
            </li>
          </ol>
        </StyledParagraph>
        <br />
        <CustomizableHeading headingLevel='h2' mobileFontSize='28px' option='bold'>
          Konsekvenser av brudd på regler:
        </CustomizableHeading>
        <StyledParagraph>
          Bryter en vurdering med disse retningslinjene vil vurderingen bli slettet. Ved grove eller gjentagende brudd vil brukeren bli utestengt fra å bidra
          til EMNR sitt innhold.
        </StyledParagraph>
        <br />
        <br />
        <CustomizableHeading mobileFontSize='28px' headingLevel='h2' option='bold'>
          Hvordan skrive en god emnevurdering:
        </CustomizableHeading>
        <StyledParagraph>
          <ul>
            <li>
              En god vurdering er
              <ul>
                <li>saklig</li>
                <li>konstruktiv</li>
                <li>konkret</li>
                <li>spesifikk</li>
              </ul>
            </li>
            <li>Gi din ærlige mening om et emne sin arbeidsmengde, vanskelighetsgrad og ditt totalinntrykk.</li>
            <li>Skriv gjerne også hva du synes var bra, og hva emnet kan gjøre for å forbedre seg.</li>
            <li>
              Din erfaring med hvordan emnet i praksis var for deg som student. Hva man lærer, hvordan øvingsopplegget er, hvor viktige forelesningene er, er
              eksempler på nyttig informasjon som gjerne kan være med i en emnevurdering.
            </li>
          </ul>
        </StyledParagraph>
      </CenteredFlexContainer>
    </Layout>
  );
};

export default GuidelinesPage;
