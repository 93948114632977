import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import { CenteredFlexContainer, ShapeContainer } from '@/styles/Containers';
import { Circle, BottomRightTriangle } from '@/styles/Shapes';
import { defaultTheme } from '@/styles/theme';
import styled from 'styled-components';
import { getLocalTokenUnverified } from '@/utils/auth';
import useFeideUriHandler from '../utils/useFeideUriHandler';
import { LoginButtonDark } from '../styles/Buttons';
import emnrLoginLogo from '../assets/images/emnr_short.svg';
import LoginHelpEmail from '../components/LoginHelpEmail';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 15vh 0 0 0;
  padding: 0 10px;
`;

const LogoLink = styled(Link)`
  width: fit-content;
  margin: 0 auto;
`;

const Logo = styled.img`
  width: 25vmax;
  margin: 0 auto;
  user-select: none;
`;

const MottoText = styled.p`
  font-family: poppinsmedium;
  font-style: italic;
  color: ${({ theme }) => theme.color.darkBlue};
  max-width: 30vmax;
  margin: -10px auto 3vh auto;
  text-align: center;
  @media ${({ theme }) => theme.device.mobileL} {
    font-size: 14px;
    max-width: 40vmax;
  }
`;

const LoginPage: React.FC = () => {
  const { handleFeideLogin } = useFeideUriHandler();

  if (getLocalTokenUnverified()) {
    return <Navigate to='/' replace />;
  }

  return (
    <CenteredFlexContainer>
      <Wrapper>
        <LogoLink to='/'>
          <Logo src={emnrLoginLogo} alt='Emnr logo' />
        </LogoLink>
        <MottoText>Hjelp til å velge NTNU-emner, for og av studenter</MottoText>
        <LoginButtonDark clickHandler={handleFeideLogin}>Logg På (Feide)</LoginButtonDark>
        <LoginHelpEmail />
      </Wrapper>
      <ShapeContainer>
        <Circle color={defaultTheme.color.red} bottom='-7vmin' left='-10vmin' size='50vmin' />
        <BottomRightTriangle color={defaultTheme.color.darkBlue} bottom='0' right='0' size='40vmin' />
      </ShapeContainer>
    </CenteredFlexContainer>
  );
};

export default LoginPage;
