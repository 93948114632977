import React from 'react';
import styled from 'styled-components';
import emnrLogo from '../assets/images/emnr_short.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  padding-bottom: 50px;
`;

const BlueOutline = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 10px solid #223455;
  background-color: transparent;
`;

const RedOutline = styled.div`
  width: 380px;
  height: 380px;
  border-radius: 50%;
  margin-top: -410px;
  border: 10px solid #d4494d;
  background-color: transparent;
`;

const Logo = styled.img`
  width: 25vmax;
  margin: 0 auto;
  user-select: none;
  width: 100px;
`;

const TextContainer = styled.div`
  margin-top: -350px;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 250px;
`;

const PageError = () => {
  return (
    <Container>
      <BlueOutline />
      <RedOutline />
      <TextContainer>
        <Logo src={emnrLogo} alt='Emnr logo' />
        <p>Oops - Noe gikk galt!</p>
        <p>Tjenesten du prøver å nå er flyttet, eller midlertidig nede.</p>
        <p>Vennligst prøv igjen senere!</p>
      </TextContainer>
    </Container>
  );
};

export default PageError;
