import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';

export interface Pagination {
  currentPage: number;
  totalPages: number;
}

export interface PaginationContextType {
  coursePagination: Pagination;
  setCoursePagination: Dispatch<SetStateAction<Pagination>>;
  reviewPagination: Pagination;
  setReviewPagination: Dispatch<SetStateAction<Pagination>>;
}

const cleanPagination: Pagination = {
  currentPage: 1,
  totalPages: 1,
};

const PaginationContext = createContext<PaginationContextType | undefined>(undefined);

export const usePaginationContext = () => {
  const context = useContext(PaginationContext);

  if (!context) throw new Error('PaginationContext: Must be in a provider');

  return context;
};

const PaginationContextProvider: FC = ({ children }) => {
  const [coursePagination, setCoursePagination] = useState(cleanPagination);
  const [reviewPagination, setReviewPagination] = useState(cleanPagination);

  return (
    <PaginationContext.Provider value={{ coursePagination, setCoursePagination, reviewPagination, setReviewPagination }}>{children}</PaginationContext.Provider>
  );
};

export default PaginationContextProvider;
