import React from 'react';
import styled from 'styled-components';

export const TextInput = styled.input`
  font-family: poppinslight;
  background-color: ${({ theme }) => theme.color.grey};
  padding-left: 15px;
  min-height: 40px;
  width: 100%;
  transition: all 0.2s ease;
  outline: none;
  border: none;
  border-radius: 15px;
  box-sizing: border-box;

  @media (${({ theme }) => theme.device.tablet}) {
    border-radius: 15px;
    border: none;
  }
  -webkit-appearance: none; // For removing ios border radius

  &::placeholder {
    color: #333;
  }

  &:focus {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    @media (${({ theme }) => theme.device.tablet}) {
      border-radius: 15px;
    }
    outline: ${({ theme }) => `1px solid ${theme.color.blue}`};
  }
`;

const SearchInput = styled(TextInput)<{ useRightLine?: boolean }>`
  border-top-right-radius: ${(props) => (!props.useRightLine ? '15px' : '0px')};
  border-bottom-right-radius: ${(props) => (!props.useRightLine ? '15px' : '0px')};
  border-right: ${(props) => props.useRightLine && '1px dotted'};

  &:focus {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    @media (${({ theme }) => theme.device.tablet}) {
      border-radius: 15px;
    }
    outline: none;
    box-shadow: 0px 0px 10px 0px rgba(168, 218, 220, 0.7);
  }
`;

interface InputProps {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setSearchFieldChanged: React.Dispatch<React.SetStateAction<boolean>>;
  searchFieldChanged: boolean;
  onEnterKeyPress: () => void;
  seperated?: boolean;
}

const Searchfield = ({ setInputValue, inputValue, setSearchFieldChanged, searchFieldChanged, onEnterKeyPress, seperated = false }: InputProps) => {
  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      onEnterKeyPress();
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
    setSearchFieldChanged(true);
  };
  if (searchFieldChanged) {
    return (
      <SearchInput
        type='search'
        useRightLine={!seperated}
        onChange={handleInputChange}
        placeholder={inputValue}
        defaultValue={inputValue}
        onKeyPress={handleKeyPress}
      />
    );
  }
  return <SearchInput type='search' useRightLine={!seperated} onChange={handleInputChange} placeholder='Søk her' onKeyPress={handleKeyPress} />;
};

export default Searchfield;
