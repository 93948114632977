import React from 'react';
import styled from 'styled-components';

interface TitleProps {
  fontSize?: string;
  mobileFontSize?: string;
  margin?: string;
  width?: string;
  textAlign?: string;
  underlined?: boolean;
  ellipsis?: boolean;
}

export const MePageCourseItemText = styled.p<TitleProps>`
  display: inline-block;
  vertical-align: top;
  text-align: ${(props) => props.textAlign || 'auto'};
  font-family: poppinsregular;
  margin: 1.5rem 0;
  padding: 0;
  text-decoration: ${(props) => (props.underlined ? 'underline' : 'none')};
  font-size: ${(props: TitleProps) => props.fontSize || '1rem'};
  text-overflow: ${(props: TitleProps) => (props.ellipsis ? 'ellipsis' : 'auto')};
  white-space: ${(props: TitleProps) => (props.ellipsis ? 'nowrap' : 'normal')};
  overflow: ${(props: TitleProps) => (props.ellipsis ? 'hidden' : 'auto')};
  color: ${({ theme }) => theme.color.darkBlue};
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 0.75em;
    margin: 1.25rem 0;
  }
`;

export const MePageCourseItemTextHeader = styled(MePageCourseItemText)`
  font-weight: 400;
  margin: 0;
  font-family: poppinsmedium;
  color: ${({ theme }) => theme.color.darkBlue};
`;

export const CourseItemSubtext = styled(MePageCourseItemText)`
  font-size: 0.75em;
`;

export const GoBackText = styled.p`
  font-family: poppinsmedium;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.color.darkBlue};
`;

export const ExtraBold = styled.b`
  font-family: poppinsmedium;
  font-weight: 900;
`;

export const RedHighlight = styled.span`
  color: ${({ theme }) => theme.color.red};
  font-family: poppinsmedium;
  font-weight: 900;
`;

export const StyledParagraph = styled.p`
  font-size: 18px;
  font-family: poppinsregular;
  font-weight: bolder;
  margin: 1em;
  color: ${({ theme }) => theme.color.darkBlue};

  @media (max-width: 768px) {
  }
`;

export interface CustomizableHeadingProps {
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  option?: 'bold' | 'subtitle';
  fontSize?: string;
  mobileFontSize?: string;
  margin?: string;
  width?: string;
  textAlign?: string;
  children?: React.ReactNode;
  fontWeight?: string;
}

export const CustomizableHeading = ({
  headingLevel,
  option,
  fontSize,
  mobileFontSize,
  margin,
  textAlign,
  children,
  fontWeight = 'bold',
}: CustomizableHeadingProps) => {
  let defaultFontSize = '24px';
  let defaultMargin = '1em';
  let defaultTextAlign = undefined;
  let mobileDefaultFontSize = '25px';

  if (option === 'bold') {
    defaultMargin = '0';
    defaultFontSize = '30px';
    defaultTextAlign = 'auto';
    mobileDefaultFontSize = '18px';
  } else if (option === 'subtitle') {
    defaultFontSize = '16px';
    defaultMargin = '0';
    defaultTextAlign = 'left';
    mobileDefaultFontSize = '16px';
  }
  const Title = styled(headingLevel)`
    font-size: ${fontSize || defaultFontSize};
    margin: ${margin || defaultMargin};
    ${option === 'bold' && 'font-weight: bold;'}
    font-family: ${option === 'bold' ? 'poppinsmedium' : 'poppinsregular'};
    font-weight: ${fontWeight};
    text-align: ${textAlign || defaultTextAlign};
    color: ${({ theme }) => theme.color.darkBlue};
    text-align: ${textAlign};
    @media ${({ theme }) => theme.device.mobileL} {
      font-size: ${mobileFontSize || mobileDefaultFontSize};
    }
    @media ${({ theme }) => theme.device.mobileS} {
      font-size: 20px;
    }
  `;
  return <Title>{children}</Title>;
};
