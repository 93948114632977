import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@/utils/auth';
import Loading from '@/components/Loading';

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { isAuthenticated, isNotAuthenticated } = useAuth();
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isAuthenticated) {
        setTimedOut(true);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [isAuthenticated]);

  if (timedOut) return <Navigate to='/login' replace />;

  if (isAuthenticated) {
    return element;
  } else if (isNotAuthenticated) return <Navigate to='/login' replace />;

  return <Loading />;
};

export default PrivateRoute;
