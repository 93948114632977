import React from 'react';
import styled from 'styled-components';
import { CarouselCardText, CarouselCardTitle, CarouselTextContainer } from '@/styles/Containers';

const CarouselCardContainer = styled.div`
  min-height: 22rem;
  max-height: 22rem;
`;
interface CarouselCardProps {
  picture: string;
  title: string;
  text: string;
  altText: string;
}

export const CarouselCard = ({ picture, title, altText, text }: CarouselCardProps) => {
  return (
    <CarouselCardContainer>
      <img src={picture} alt={altText} width={'100px'} height={'100px'} />
      <CarouselTextContainer>
        <CarouselCardTitle>{title}</CarouselCardTitle>
        <CarouselCardText>{text}</CarouselCardText>
      </CarouselTextContainer>
    </CarouselCardContainer>
  );
};
