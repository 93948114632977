import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';

// pages
import NotFound from '@/components/NotFound';
import AboutPage from '@/pages/AboutPage';
import CoursePage from '@/pages/CoursePage';
import FrontPage from '@/pages/FrontPage';
import GuideLinesPage from '@/pages/GuideLinesPage';
import LoginPage from '@/pages/LoginPage';
import MeDeletePage from '@/pages/MeDeletePage';
import MePage from '@/pages/MePage';
import SearchPage from '@/pages/SearchPage';
import VerifyLogin from '@/pages/VerifyLogin';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

const RoutesView = () => {
  return (
    <Routes>
      <Route path='/verifylogin' element={<VerifyLogin />} />
      <Route path='/course/:id' element={<CoursePage />} />
      <Route path='/me/delete' element={<PrivateRoute element={<MeDeletePage />} />} />
      <Route path='/me' element={<PrivateRoute element={<MePage />} />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/guidelines' element={<GuideLinesPage />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/search' element={<SearchPage />} />
      <Route path='/' element={<FrontPage />} />
      <Route path='*' element={<NotFound />} />
      <Route path='/privacy' element={<PrivacyPolicyPage />} />
    </Routes>
  );
};

export default RoutesView;
