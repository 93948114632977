import { useEffect, useState } from 'react';
import { useGetFeideLoginQuery } from './auth';

const useFeideUriHandler = () => {
  const [feideURI, setFeideURI] = useState<string>('');
  const { data } = useGetFeideLoginQuery();

  useEffect(() => {
    const fetchURL = async () => {
      setFeideURI(data);
    };
    fetchURL();
  }, [data]);

  const handleFeideLogin = async () => {
    sessionStorage.setItem('previousLocation', window.location.pathname);
    if (feideURI) {
      window.location.assign(feideURI);
    }
  };

  return { feideURI, handleFeideLogin };
};

export default useFeideUriHandler;
