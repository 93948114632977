import React, { useState } from 'react';
import styled from 'styled-components';
import ThumbsUpIcon from '@/assets/icons/thumbsUp.svg';
import ThumbsUpIconOutlined from '@/assets/icons/thumbsUpOutlined.svg';
import axios from 'axios';
import API_URL from '@/config';
import { TooltipButtonContainer } from '@/styles/Buttons';
import { TooltipText } from '@/components/RateCourseButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const ThumbsUpBtn = styled.img`
  height: 28px;
  float: left;
  cursor: pointer;
`;

const ClickedThumbsUp = styled(ThumbsUpBtn)`
  animation: like 300ms ease-out;
  @keyframes like {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.4);
      height: 28px;
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ThumbsUpText = styled.p`
  margin: 5px 0 0 5px;
  font-family: poppinsregular;
  user-select: none;
  cursor: default;
`;

interface ReviewProps {
  reviewId: number;
  upvoteStatus: number;
  numUpvotes: number;
}

const useUpvoteMutations = (reviewId: number, upvoteStatus: number, numUpvotes: number) => {
  const [status, setStatus] = useState<number>(upvoteStatus);
  const [count, setCount] = useState<number>(numUpvotes);
  const queryClient = useQueryClient();

  const mutationOptions = {
    onSettled: () => {
      queryClient.invalidateQueries(['review', reviewId]);
    },
  };

  const addUpvote = useMutation(
    async () => {
      await axios.post(`${API_URL}/upvote/?reviewId=${reviewId}`);
    },
    {
      ...mutationOptions,
      onSuccess: () => {
        setStatus(1);
        setCount((n) => n + 1);
      },
    },
  );

  const removeUpvote = useMutation(
    async () => {
      await axios.delete(`${API_URL}/upvote/delete/?reviewId=${reviewId}`);
    },
    {
      ...mutationOptions,
      onSuccess: () => {
        setStatus(0);
        setCount((n) => n - 1);
      },
    },
  );

  const toggleUpvote = (): void => {
    // Status 0: Can upvote
    // Status 1: Upvoted
    if (status === 0) {
      addUpvote.mutate();
    } else if (status === 1) {
      removeUpvote.mutate();
    }
  };

  return { toggleUpvote, status, count };
};

const Upvote = ({ reviewId, upvoteStatus, numUpvotes }: ReviewProps) => {
  const { toggleUpvote, count, status } = useUpvoteMutations(reviewId, upvoteStatus, numUpvotes);

  let content;

  switch (status) {
    // Status 0: Can upvote
    // Status 1: Upvoted
    // Status 2: User cannot review because its expiring token does not exist.
    // Status 3: User is banned from making upvotes
    case 1:
      content = <ClickedThumbsUp src={ThumbsUpIcon} onClick={toggleUpvote} />;
      break;
    case 2:
      content = (
        <TooltipButtonContainer>
          <ClickedThumbsUp src={ThumbsUpIcon} />
          <TooltipText>Din token har utløpt.</TooltipText>
        </TooltipButtonContainer>
      );
      break;
    case 3:
      content = (
        <TooltipButtonContainer>
          <ClickedThumbsUp src={ThumbsUpIcon} />
          <TooltipText>Du har blitt utestengt.</TooltipText>
        </TooltipButtonContainer>
      );
      break;
    default:
      content = <ThumbsUpBtn src={ThumbsUpIconOutlined} onClick={toggleUpvote} />;
      break;
  }

  return (
    <>
      {content}
      <ThumbsUpText>{count}</ThumbsUpText>
    </>
  );
};

export default Upvote;
