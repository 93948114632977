import React from 'react';
import styled from 'styled-components';

interface PersonProps {
  name: string;
  role?: string;
  imgSrc: string;
  linkedIn?: string;
}

const PersonContainer = styled.div<{ extraMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  @media ${({ theme }) => theme.device.tablet} {
    width: 140px;
  }
  @media ${({ theme }) => theme.device.mobileL} {
    width: 100px;
  }
`;

const Image = styled.img`
  width: 170px;
  height: 170px;
  @media ${({ theme }) => theme.device.tablet} {
    width: 140px;
    height: 140px;
  }
  @media ${({ theme }) => theme.device.mobileL} {
    width: 100px;
    height: 100px;
  }
`;

const Caption = styled.span`
  display: flex;
  justify-content: center;
`;

const Anchor = styled.a`
  text-decoration: none;
  font-family: poppinsmedium;
  color: ${({ theme }) => theme.color.darkBlue};
`;

const Role = styled.p`
  margin: 0;
  font-family: poppinsregular;
`;

const Person: React.FC<PersonProps> = ({ name, role, imgSrc, linkedIn }) => {
  return (
    <PersonContainer extraMargin={role !== undefined}>
      <Anchor href={linkedIn} target='_blank'>
        <Image src={imgSrc} alt={name} />
      </Anchor>

      <Caption>
        <Anchor href={linkedIn} target='_blank'>
          {name}
          {role && <Role>{role}</Role>}
        </Anchor>
      </Caption>
    </PersonContainer>
  );
};

export default Person;
